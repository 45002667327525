import React from "react";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import { Link } from "react-router-dom";
import Translator from "../../../components/Translator";
import { Divider, List, Typography } from 'antd';
import { useTranslation } from "react-i18next";

const Admin = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	
	const data = [
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/admin/dashboard'}><Translator path={'administracao.dashboard'} /></Link>,
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/admin/loterias_desdobramentos'}><Translator path={'desdobramentos'} /></Link>,
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/admin/cupons'}><Translator path={'administracao.cupons'} /></Link>
	];

	return(
		<div>
			<h2 className="title is-1">{t('administracao.admin')}</h2>
			<br/>
			<hr/>
			<br/>
			
			{/* <YoutubeEmbed embedId="rokGy0huYEA"/> */}
			<List
				size="large"
				header={<div>{t('administracao.opcoes')}</div>}
				//footer={<div>Footer</div>}
				bordered
				dataSource={data}
				renderItem={(item) => <List.Item>{item}</List.Item>}
			/>
		</div>
	);
}


export default Admin;