import React from "react";
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import { Divider, List, Typography, Card, Flex, Carousel, ConfigProvider, Image } from 'antd';
import YoutubeEmbed from "../components/YoutubeEmbed";
import { Translate } from "../services/translate";

const PreenchimLot = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { auth } = useAuth();

	const contentStyle = {
		margin: 0,
		width: '100%',
		padding: 50,
		color: '#fff',
		lineHeight: '160px',
		textAlign: 'center',
		background: '#364d79',
	   };

	return(
		<div>
			<h2 className="title is-1">{t('preenchimento_loteria')}</h2>
			<br/>
			<hr/>
			<br/>
			<Flex wrap gap="middle" justify="center" align="center" horizontal>
				<a href="https://www.google.com.br" target="_blank"><img src="/img/chrome-store.png" alt="Chrome web store" style={{width: (200)}}/></a>
				<a href="https://www.google.com.br" target="_blank"><img src="/img/google-play.png" alt="Google play" style={{width: (200)}}/></a>
				<a href="https://www.google.com.br" target="_blank"><img src="/img/apple-store.png" alt="Apple store" style={{width: (200)}}/></a>
			</Flex>
			<br/>
			<br/>
			<ConfigProvider
  theme={{
    components: {
      Carousel: {
		arrowSize: 24
      },
    },
  }}
>
			<Carousel arrows infinite={false} style={{padding:25, backgroundColor:"#000",maxWidth:903,
			maxHeight:530}}>
				<div>
					<div >
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div>
				</div>
				<div>
				<div>
				<Flex wrap gap="middle" justify="center" align="center" horizontal>
				<Image
					//width={100}
					style={{maxWidth:"850px",height:"auto", maxHeight:"480px",margin:"auto"}}
					src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
					/>
					</Flex>
				</div>
				</div>
				{/* <div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div>
				<div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div>
				<div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div> */}
			</Carousel>
			</ConfigProvider>
			<br/>
			<br/>
			<h2 className="title is-1">Visão geral</h2>
			<br/>
			<br/>
		</div>
	);
}


export default PreenchimLot;