import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist, getSessionId } = useAuth();
    //console.log(auth);
    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {

            //console.log(getSessionId());

            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        //console.log('Refresh',persist,auth?.user);

        // persist added here AFTER tutorial video
        // Avoids unwanted call to verifyRefreshToken
        //!auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
        //console.log('auth',auth);
        //!auth?.user === "" && persist ? verifyRefreshToken() : setIsLoading(false);
        //(auth?.user === "" || auth?.user === undefined) && persist? verifyRefreshToken() : setIsLoading(false);
        getSessionId() !== "" && persist ? verifyRefreshToken() : setIsLoading(false);
        //setIsLoading(false);

        return () => isMounted = false;
    }, [])

    // useEffect(() => {
    //     //console.log(`isLoading: ${isLoading}`)
    //     //console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    //     //console.log(`auth: ${JSON.stringify(auth)}`)
    // }, [isLoading])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />}/>
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin