import React from "react";
import { Button, Result } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';


const NotFound = () => {

	window.scrollTo(0, 0);
	let navigate = useNavigate();

	return(
		<div>
			<h2 className="title is-1">Página não encontrada</h2>
			<br/>
			<hr/>
			<br/>
			<Result
				status="404"
				title="404"
				subTitle=""
				extra={<Button type="primary" onClick={()=>{navigate('/');}}>Voltar para a página inicial</Button>}
			/>
		</div>
	);
}


export default NotFound;