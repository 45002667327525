import React, { useRef, useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
//import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';

const VerificarEmail = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const { setAuth, auth, persist, setPersist } = useAuth();
	const errRef = useRef();

	const [form] = Form.useForm();
	const [email, setEmail] = useState('');

	const [validEmail, setValidEmail] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccess] = useState(false);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
    		const em = queryParams.get("email");
		setEmail(em);
		form.setFieldValue("email_inp", em);
		if(em != ""){
			//handleSubmit(emailRef);
		}
	}, []);

	useEffect(() => {
		setErrMsg('');
		setSuccess(false);
		setValidEmail(email !== "");		
	}, [email])

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);

		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);

		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = email !== "";

		if (!v1) {
			setErrMsg(t('cadastro_msg.email_err'));
			return;
		}

		try {
			const response = await axiosPrivate.post('/VerificarEmail', { em : email});

			if (response.status === 200) {
				//console.log(response.data);
				setSuccess(false);
				if (response.data !== null && response.data !== undefined && response.data !== "") {
		
					switch(response.data.error_code) {
						case 0:

							setSuccess(true);
							setErrMsg('');
							break;
							//sucRef.current.focus();
						case 1:
							
							setErrMsg('verif_email.error_1');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('verif_email.error_2');
							//setSuccess(false);
							break;
						case 3:
							setErrMsg('verif_email.error_3');
							//setSuccess(false);
							break;
						default:
							setErrMsg('verif_email.error_4');
							//setSuccess(false);
					 }

				}else{
					setErrMsg('verif_email.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('verif_email.error_6');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('verif_email.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('verif_email.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('verif_email.error_9');
				//setSuccess(false);
			}
		}
	}

	return (
		<div>
			<h2 className="title is-1">{t('valid_msg.verificar_email')}</h2>
			<br />
			<hr />
			<br />
			<div
				style={{
					//height: 300, 
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<Form
					form={form}
					style={{
						maxWidth: 320,
					}}
					scrollToFirstError
					name="normal_login"
					// defaultValues={{
					// 	email_inp: email,
					// }}
				//onFinish={onFinish}
				>

					{successMsg === true ? (
						<><Alert message={t('valid_msg.email_enviado')} type="success" showIcon /><br /></>
					) : (<></>)}

					{errMsg !== "" ? (
						<><Alert ref={errRef} message={t(errMsg)} type="error" showIcon /><br /></>
					) : (<></>)}

					<Form.Item
						name="email_inp"
						label={t('cadastro_msg.email')}
						onChange={(e) => setEmail(e.target.value)}
						maxLength={255}
						rules={[
							{
								required: true,
								type: 'email',
								message: t("cadastro_msg.email_err"),
							},
							() => ({
								validator(_, value) {
								if (value !== "") {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.falta_email")));
								},
							}),
						]}
					>
						<Input/>
					</Form.Item>

					<Form.Item>
						<SubmitButton disabled={!validEmail ? true : false} form={form} on_click={handleSubmit}>{t('valid_msg.enviar')}</SubmitButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
};

export default VerificarEmail