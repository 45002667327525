import React, { useRef, useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
//import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';

const ConfirmEmail = () => {

	window.scrollTo(0, 0);

	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccess] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		const ce = queryParams.get("ce");
		getConfirmEmail(ce);
	}, []);

	const getConfirmEmail = async (ce) => {

		try {
			const response = await axiosPrivate.post('/ConfirmacaoEmail', { ce: ce });

			if (response.status === 200) {
				//console.log(response.data);
				//setSuccess(false);
				if (response.data !== null && response.data !== undefined && response.data !== "") {

					switch (response.data.error_code) {
						case 0:

							setSuccess(true);
							setErrMsg('');
							break;
						//sucRef.current.focus();
						case 1:

							setErrMsg('confirm_email.error_1');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('confirm_email.error_2');
							//setSuccess(false);
							break;
						case 4:
							setErrMsg('confirm_email.error_4');
							//setSuccess(false);
							break;
						default:
							setErrMsg('confirm_email.error_3');
						//setSuccess(false);
					}

				} else {
					setErrMsg('confirm_email.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('confirm_email.error_6');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('confirm_email.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('confirm_email.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('confirm_email.error_9');
				//setSuccess(false);
			}
		}

	};

	return (
		<div>
			<h2 className="title is-1">{t('valid_msg.confirm_email')}</h2>
			<br />
			<hr />
			<br />
			<div
				style={{
					//height: 300, 
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<Form
					form={form}
					style={{
						maxWidth: 320,
					}}
					scrollToFirstError
					name="normal_login"
				// defaultValues={{
				// 	email_inp: email,
				// }}
				//onFinish={onFinish}
				>

					{successMsg === true ? (
						<>
							<Alert message={t('valid_msg.email_valid_suc')} type="success" showIcon /><br />
							<p>{t('valid_msg.desc_ir_login')}</p><br />
							<Form.Item>
								<Button type="primary" onClick={() => {navigate('/login', {replace: true})}}>{t('valid_msg.ir_login')}</Button>
							</Form.Item>
							{/* <Link to={window.location.origin.toString() + '/login'}>{t('resgatar_cupom_msg.click_login')}</Link> */}
						</>
					) : (<></>)}

					{errMsg !== "" ? (
						<>
							<Alert message={t(errMsg)} type="error" showIcon /><br />
							<p>{t('valid_msg.desc_ir_reenvio')}</p><br />
							<Form.Item>
								<Button type="primary" onClick={() => {navigate('/verificar_email', {replace: true})}}>{t('valid_msg.ir_reenvio')}</Button>
							</Form.Item>
							{/* <Link to={window.location.origin.toString() + '/login'}>{t('resgatar_cupom_msg.reenviar')}</Link> */}
						<br /></>
					) : (<></>)}

					{/* <Form.Item>
						<Button on_click={""}>{t('resgatar_cupom_msg.resgatar')}</Button>
					</Form.Item>
					<Link to={window.location.origin.toString() + '/verificar_email'}>{t('resgatar_cupom_msg.resgatar')}</Link> */}
				</Form>
			</div>
		</div>
	)
};

export default ConfirmEmail