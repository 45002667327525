import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { disableReactDevTools} from '@fvilers/disable-react-devtools';
import './i18n'

if(process.env.NODE_ENV === 'production'){
	disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>		
		<BrowserRouter>
			<AuthProvider>
				<App />
			</AuthProvider>			
		</BrowserRouter>
	</React.StrictMode>
);

