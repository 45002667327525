export function padTwoDigits(num) {
     return num.toString().padStart(2, "0");
}

export function dateInDdMmYyyyHhMmSs(date, dateDiveder = "/") {
     return (
          [
               padTwoDigits(date.getDate()),
               padTwoDigits(date.getMonth() + 1),               
               date.getFullYear()
          ].join(dateDiveder) +
          " " +
          [
               padTwoDigits(date.getHours()),
               padTwoDigits(date.getMinutes()),
               padTwoDigits(date.getSeconds()),
          ].join(":")
     );
}

export function dateInDdMmYyyy(date, dateDiveder = "/") {
     return (
          [
               padTwoDigits(date.getDate()),
               padTwoDigits(date.getMonth() + 1),               
               date.getFullYear()
          ].join(dateDiveder)
     );
}