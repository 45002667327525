import React from "react";
import { useEffect, useState } from "react";
import '../styles/planos.css';
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import { Translate } from "../services/translate";
import SubscribeCard from "../components/SubscribeCard";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Flex, Result, Collapse, Spin } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';

const Assinaturas = () => {

	window.scrollTo(0, 0);
	const axiosPrivate = useAxiosPrivate();
	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const { auth } = useAuth();
	const [planos, setPlanos] = useState([]);
	const [loading_planos, setLoadPlanos] = useState(false);

	let items = [];

	useEffect(() => {
		setLoadPlanos(true);
		getInfoPlanos();
	}, []);

	const getInfoPlanos = async () => {

		try {
			const response = await axiosPrivate.post('/Subscriptions');

			if (response.status === 200) {

				if(response.data.length > 0){

					items = [];
					let planos = JSON.parse(response.data[0].jsonInfo);

					planos.map((categoria, i) => {

						let qtd_funtionalties = 0;
						categoria.subscriptions.map((subscription, index) =>
						{
							//console.log(subscription);
							if(subscription.subscription_functionalities.length > qtd_funtionalties){
								qtd_funtionalties = subscription.subscription_functionalities.length;
								//console.log(qtd_funtionalties);
							}
						})

						items.push({
							key: i,
							label: <Translate path={categoria.category}/>,
							children: (
								<Flex wrap gap="small">
									{
										categoria.subscriptions.map((card, index) =>
										{
											card.max_func = qtd_funtionalties;
											return(<SubscribeCard key={index + 1} card_info={card} />)
										})										
									}
								</Flex>
							),
						});						
					});

					setPlanos(()=>{
						return [...items];
					});
					// modalidades[index].combinacoes = JSON.parse(response.data[0].jsonInfo);
					// 	modalidades[index].combinacoes.map((comb, i) => {
					// 		comb.qty_disp = modalidades[index].qty_disp;
					// 		comb.qty_min_gain = modalidades[index].qty_min_gain;
					// 		comb.qty_result = modalidades[index].qty_result;							
					// 	});

					// setDatasource(()=>{
					// 	return [...modalidades[index].combinacoes];
					// });
				}else{
					// setDatasource(()=>{
					// 	return [];
					// });
				}
			} else {
				// setDatasource(()=>{
				// 	return [];
				// });
			}
		} catch (err) {
			// setDatasource(()=>{
			// 	return [];
			// });
		}
		setLoadPlanos(false);
	};

	return (
		<div>
			<Spin spinning={loading_planos} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />}>
				<h2 className="title is-1">{t('assinaturas')}</h2>
				<br />
				<hr />
				<br />
				<br />
				<Collapse
					accordion
					defaultActiveKey={['0']}
					items={planos}
				/>
			</Spin>
		</div>
	);
}


export default Assinaturas;