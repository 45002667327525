import React, { useEffect, useState, Component, useMemo, useCallback, useTransition, Children } from "react";
import { useTranslation } from "react-i18next";
import { AutoComplete, Button, Cascader, Checkbox, Col, Form, Input, InputNumber, Row, Select, Alert, Table, Progress, Upload, Flex } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import * as XLSX from 'xlsx';

const DesdobramentosAdmin = () => {

	window.scrollTo(0, 0);
	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const [progress, setProgress] = useState(0);
	const [form] = Form.useForm();

	const process_comb = useCallback((prog) => {
		setProgress(() => { return (prog) });
	}, [progress]);

	let modalidades = [
		{
			key: 0,
			value: 'dupla_sena',
			label: 'Dupla/Mega Sena',
			qty_comb_min: 6,
			qty_comb_max: 15,
			qty_result: 6,
			qty_disp: 50,
			qty_min_gain: 3,
			combinacoes: [],
			probabilities: []
		},
		{
			key: 1,
			value: 'lotofacil',
			label: 'Lotofácil',
			qty_comb_min: 15,
			qty_comb_max: 20,
			qty_result: 15,
			qty_disp: 25,
			qty_min_gain: 11,
			combinacoes: [],
			probabilities: []
		},
		{
			key: 3,
			value: 'quina',
			label: 'Quina',
			qty_comb_min: 5,
			qty_comb_max: 15,
			qty_result: 5,
			qty_disp: 80,
			qty_min_gain: 2,
			combinacoes: [],
			probabilities: [],
			disabled: false
		}
	];

	

	const [modalidade_sel, setModalidadeSel] = useState("");
	const [resumo_val, setResumo] = useState([]);
	const [lista_upload, setListUpload] = useState([]);
	const [columns_info, setModalColumns] = useState([]);
	const [qtd_num_sel, setQtdSel] = useState(0);
	const handleChange = (value) => {
		setModalidadeSel(value);
	};

	useEffect(() => {
		for (let i = 0; i < modalidades.length; i++) {
			if (modalidades[i].value === modalidade_sel) {
				form.setFieldValue("qty_result",modalidades[i].qty_result);
				form.setFieldValue("qty_min_gain",modalidades[i].qty_min_gain);
				form.setFieldValue("qty_disp",modalidades[i].qty_disp);
			}
		}
	 },[modalidade_sel]);

	const handleFileUpload = useCallback((e) => {

		ExcelToJSON(e);

	}, []);

	const ExcelToJSON = function (e) {

		setResumo([]);
		setListUpload([]);

		const file = e.target.files[0];
		const parseExcel = function (file) {

			var reader = new FileReader();
			reader.onload = function (e) {
				var data = e.target.result;
				var workbook = XLSX.read(data, {
					type: 'binary'
				});
				workbook.SheetNames.forEach(function (sheetName) {

					const sheet = workbook.Sheets[sheetName];
					var range = XLSX.utils.decode_range(sheet['!ref']);
					for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {

						//process_comb(rowNum);
						let comb = [];
						for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {

							const secondCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
							//console.log(secondCell);
							if (secondCell != undefined) {

								if (secondCell.v != "") {
									if (secondCell.v > max_num) { max_num = secondCell.v; }
									comb.push(secondCell.v);
								}
							}

						}
						lista_gerada.push(comb);
					}

					//console.log(lista_gerada);
					//console.log(JSON.stringify(lista_gerada));

				})
				//console.log(max_num);
				setQtdSel(max_num);
				setListUpload(() => { return [...lista_gerada] });

				validar_acuracidade(form.getFieldValue("qty_min_gain"), form.getFieldValue("qty_result"), max_num);
			};

			reader.onerror = function (ex) {
				console.log(ex);
			};

			reader.readAsBinaryString(file);

		};
		parseExcel(file);
	};

	let resumo = [];
	let lista_final = [];
	let lista_gerada = [];
	let max_num = 0;

	const gerar_comb = async (qtd_tot, qtd_jogo) => {

		let aux = qtd_jogo - 1;
		let aux_2 = qtd_jogo - 1;
		let comb = [];
		let comb_ant = [];
		lista_final = [];

		//Primeira combinação
		for (let i = 0; i < qtd_jogo; i++) {
			comb[i] = i + 1;
			comb_ant[i] = i + 1;
		}
		lista_final.push(comb);

		//Verifica as demais combinações
		while (comb[0] <= (qtd_tot - qtd_jogo)) {
			comb = [];
			for (let i = 0; i < qtd_jogo; i++) {
				comb[i] = comb_ant[i];
			}

			if (comb[aux_2] < qtd_tot) {
				comb[aux_2]++;
			}
			else {
				aux = qtd_jogo - 2;
				while (comb[aux] == (qtd_tot - (qtd_jogo - aux - 1))) {
					aux--;
				}
				comb[aux]++;
				for (let i = aux + 1; i < qtd_jogo; i++) {
					comb[i] = comb[i - 1] + 1;
				}
			}

			for (let i = 0; i < qtd_jogo; i++) {
				comb_ant[i] = comb[i];
			}
			lista_final.push(comb);

		}
	}

	const validar_acuracidade = async (qtd_comb_ini, qtd_comb_fim, qtd_tot) => {
		resumo = [];
		for (let i = qtd_comb_fim; i >= qtd_comb_ini; i--) {

			await gerar_comb(qtd_tot, i);
			let res_val = await validar_gerados(qtd_comb_fim);
			resumo.push({
				//'key' : i,
				'qtd_comb': i,
				'qtd_tot_comb': lista_final.length,
				'acuracidade': res_val
			});
			await process_comb((qtd_comb_fim - 1) * 10.0);
		}
		console.log(resumo);
		setResumo(() => { return [...resumo] });

		updateColumns(qtd_tot, form.getFieldValue("qty_disp"),form.getFieldValue("qty_min_gain"), resumo);
	}

	const validar_gerados = async (qtd_comb) => {

		let res_val = [];
		for (let i = 0; i <= qtd_comb; i++) {
			res_val[i] = 0;
		}

		let qtd_tot_comb = lista_final.length;
		let qtd_tot_gerad = lista_gerada.length;

		for (let i = 0; i < qtd_tot_comb; i++) {
			let val_max = 0;
			for (let j = 0; j < qtd_tot_gerad; j++) {
				let qtd_acert = await compare_array(lista_final[i], lista_gerada[j]);
				if (qtd_acert > val_max) {
					val_max = qtd_acert;
					if (val_max === qtd_comb) {
						j = qtd_tot_gerad;
					}
				}
			}
			res_val[val_max]++;
		}

		return res_val;
	}

	const compare_array = async (comb_1, comb_2) => {
		let qtd = 0;
		for (let i = 0; i < comb_1.length; i++) {
			if (comb_2.includes(comb_1[i])) {
				qtd++;
			}
		}
		return qtd;
	}

	function objectToGraphData(obj) {
		// Make sure quotes within values are not affected by temporarily replacing them

		let quotePlaceholder = "#!-QUOT-" + Math.random() + "-QUOT-!#";

		let json = JSON.stringify(obj, function (key, val) {
			if (typeof (val) === "string") {
				return val.replace(/"/g, quotePlaceholder);
			}

			return val;
		});

		// Remove quotes surrounding property names and restore quotes in values

		json = json.replace(/"([^"]+?)":/g, "$1:");
		json = json.replace(new RegExp(quotePlaceholder, "g"), "\\\"");

		return json;
	}


	const enviar_comb = async () => {
		form.setFieldValue("prob",JSON.stringify(resumo_val));
		console.log(JSON.stringify(resumo_val));
		console.log(
			{
				key: 0,
				public_comb: form.getFieldValue("public") ? 1 : 0,
				qty_num_comb: lista_upload[0]?.length,
				qty_hits: form.getFieldValue("qty_hits_comb"),
				qty_num_tot: qtd_num_sel,
				qty_min_res: form.getFieldValue("qty_min_res"),
				qty_comb: lista_upload.length,
				combi: JSON.stringify(lista_upload),
				//probab: form.getFieldValue("prob"),//"[]",//JSON.stringify(resumo_val),//objectToGraphData(resumo_val),
				probab: JSON.stringify(resumo_val),
				accuracy: form.getFieldValue("accuracy")
			}
		);

		try {

			if (lista_upload.length > 0) {

				const response = await axiosPrivate.post('/InputLotteryCombination', {
					public_comb: form.getFieldValue("public") ? 1 : 0,
					qty_num_comb: lista_upload[0]?.length,
					qty_hits: form.getFieldValue("qty_hits_comb"),
					qty_num_tot: qtd_num_sel,
					qty_min_res: form.getFieldValue("qty_min_res"),
					qty_comb: lista_upload.length,
					combi: JSON.stringify(lista_upload),
					//probab: "[]",//JSON.stringify(resumo_val),//objectToGraphData(resumo_val),
					//probab: JSON.stringify(resumo_val).replace('"','\''),
					probab: JSON.stringify(resumo_val),
					//probab: '{"A":1}',
					accuracy: form.getFieldValue("accuracy")
				});

				if (response.status === 200) {
					alert('OK=', JSON.stringify(response.data));

				} else {
					alert('ERRO=', JSON.stringify(response.data));
				}

			} else {
				alert('Não foram geradas combinações para serem enviadas!')
			}

		} catch (err) {
			alert(err);
		}

	}

	let columns = [];
	const updateColumns = (qty_num_tot, qty_disp, qty_min_gain, prob_json) => {
		columns = [];

		columns.push({
			title: '',
			align: 'center',
			//fixed: 'left',
			key: 0,
			width: '30vw',
			render: (_, record) => {
				return ("Acertando " + record.qtd_comb + " números dos " + qty_num_tot + " escolhidos")
			}
		},
			{
				title: 'Probabilidade de acertar',
				align: 'center',
				//fixed: 'left',
				key: 99,
				children: []
			});

		if (prob_json?.length > 0) {

			prob_json?.map((prob, i) => {
				columns[1].children.push({
					title: prob.qtd_comb,
					align: 'center',
					key: i + 100,
					width: '6vw',
					render: (_, record) => {
						return (record.acuracidade[prob.qtd_comb] === 0 ? '' : (record.acuracidade[prob.qtd_comb] / record.qtd_tot_comb * 100.0).toFixed(2) + " %")
					}
				});

			});
		}

		setModalColumns(
			() => [...columns]
		)
	}

	return (
		<div>
			<h2 className="title is-1">Desdobramentos Admin</h2>
			<br />
			<hr />
			<br />
			<Flex wrap gap="small" justify={'center'} align={'center'}>
				<label>
					Modalidade: <Select
						defaultValue=""
						style={{
							width: 120,
						}}
						onChange={handleChange}
						options={modalidades}
					/>
				</label>
			</Flex>
			<Form

				form={form}
				//onSubmit={handleSubmit}
				name="register"
				//onFinish={handleSubmit}
				style={{
					maxWidth: 600,
				}}
				scrollToFirstError
			>
				{/* {errMsg != "" && success === false ? (
						<><Alert ref={errRef} message={errMsg} type="error" showIcon /><br/></>
					) : (<></>)}
					
					{success === true && errMsg == "" ? (
						<><Alert ref={sucRef} message={"Cadastro realizado com sucesso! Você receberá um e-mail para confirmação."} type="success" showIcon/><br/></>
					) : (<></>)} */}

				<Form.Item
					id="public_inp"
					name="public"
					valuePropName="checked"
				//onChange={""}
				>
					<Checkbox
					//onChange={(e) => setTerms(e.target?.checked ? true : false)}
					>
						Público
					</Checkbox>
				</Form.Item>

				<Form.Item
					id="qty_result_inp"
					name="qty_result"
					label="Quantidade sorteada"
				//onChange={""}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					id="qty_min_gain_inp"
					name="qty_min_gain"
					label="Quantidade minima para ganho"
				//onChange={""}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					id="qty_disp_inp"
					name="qty_disp"
					label="Quantidade disponível para seleção"
				//onChange={""}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					id="qty_hits_inp"
					name="qty_hits_comb"
					label="Condição de acertos"
				//onChange={""}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					id="qty_min_res_inp"
					name="qty_min_res"
					label="Resultado esperado"
				//onChange={""}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					id="accuracy_inp"
					name="accuracy"
					label="Acuracidade"
				//onChange={""}
				>
					<InputNumber step="0.01" />
				</Form.Item>

				<Form.Item
					id="prob_inp"
					name="prob"
					label="Probabilidades"
				//onChange={""}
				>
					<Input/>
				</Form.Item>

				<Form.Item>
					{/* <SubmitButton disabled={!validName || !validEmail || !validPwd || !validMatch || !validTerms  ? true : false} form={form} on_click={handleSubmit}>Cadastrar</SubmitButton> */}
				</Form.Item>
			</Form>
			<Progress percent={progress} percentPosition={{ align: 'center', type: 'inner' }} size={[300, 20]} />
			<input type="file" onChange={handleFileUpload} />
			<br />
			<Button size={'large'} onClick={() => { enviar_comb() }}>Enviar</Button>
			<br />
			<br />
			<br />
			<br />
			<Table
				columns={columns_info}
				dataSource={resumo_val}
				bordered
				size="small"
				scroll={{
					x: 700,
				}}
				pagination={{ hideOnSinglePage: true }}
			/>
		</div>
	);
}


export default DesdobramentosAdmin;