import React from "react";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import { Link } from "react-router-dom";
import Translator from "../../../components/Translator";
import { Divider, List, Typography } from 'antd';
import { useTranslation } from "react-i18next";

const Cupons = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	
	return(
		<div>
			<h2 className="title is-1">{t('administracao.cupons')}</h2>
			<br/>
			<hr/>
			<br/>
			
		</div>
	);
}


export default Cupons;