import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const hs = '$2a$12$vf5J1rN32sCdfVDEZw2xlu';
    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);
    const [session, setSession] = useState(localStorage.getItem("session_id") || "");

    const habRoles = (role) => {

        //Verifica se contém algum em role[] para habilitar a funcionalidade
        const decoded = auth?.access_token !== undefined && auth?.access_token !== ""
        ? jwtDecode(auth?.access_token)
        : undefined;

        let user_roles = [];
        let jsonInfo = decoded?.roles || "[]";
        jsonInfo = JSON.parse(jsonInfo);

        if(jsonInfo.length > 0){
            user_roles = jsonInfo || [];
        }

        let hab = false;
        user_roles.map((ro, index) =>{
            for(let i = 0; i < role.length; i++){
                if(ro === role[i]){
                    hab = true;
                }
            }
        })

        return hab;//user_roles.includes(role);
    }

    const setSessionId = (session_id) => {
        if(session_id == ""){
            localStorage.removeItem("session_id");
            setSession("");
        }
        else{
            localStorage.setItem("session_id", session_id);
            setSession(session_id);
        }
    }

    const getSessionId = () => {
        return session;
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, habRoles, getSessionId, setSessionId, hs}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;