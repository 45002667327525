import React, { useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import { Select, Space, Table, Tooltip, Flex , Spin, notification, Button, Popconfirm } from 'antd';

const ApostasEsportivasEstatisticas = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { auth } = useAuth();

	const [dataSource, setDatasource] = useState([]);

	const values = [
		{
			"competicao" : "Série A",
			"data" : "Qua 11 Sep",
			"horario" : "19:30",
			"time_1" : "Internacional",
			"time_2" : "Fortaleza",
			"odd_time_1" : 2.05,
			"odd_empate" : 3.30,
			"odd_time_2" : 3.90,
		}
	];

	const columns = [
		{
		  title: 'Competição',
		  dataIndex: 'competicao',
		},
		{
			title: "Data",
			dataIndex: 'data'
		},
		{
			title: "Horário",
			dataIndex: 'horario'
		},
		{
			title: "Time 1",
			dataIndex: 'time_1'
		},
		{
			title: "Time 2",
			dataIndex: 'time_2'
		},
		{
			title: "1",
			dataIndex: 'odd_time_1'
		},
		{
			title: "X",
			dataIndex: 'odd_empate'
		},
		{
			title: "2",
			dataIndex: 'odd_time_2'
		}	
	   ];

	return(
		<div>
			<h2 className="title is-1">{t('apostas_esportivas_estatisticas')}</h2>
			<br/>
			<hr/>
			<br/>
			<Table id="tableList"
					
					size="small"
					columns={columns} 
					dataSource={values} 
					//onChange={onChange}
					pagination={{ pageSize: 50, size:"small", hideOnSinglePage:true/*,showSizeChanger: true */}}
					scroll={{
						x: 700,
					}}
				/>
		</div>
	);
}


export default ApostasEsportivasEstatisticas;