import React from 'react';
import { useTranslation } from "react-i18next";
import '../styles/terms.css';

const Terms = ({lang}) => {

	const { t, i18n: {changeLanguage, language} } = useTranslation();

     if(language == "pt-BR"){
          return (
               <>
                    {/* <h2>Termos e Condições de Uso</h2> */}
                    <p><strong>Última atualização:</strong> 07/09/2024</p>
                    <br/>
                    <h2>1. Identificação da Empresa</h2>
                    <p className='term_just'>Este site é operado por <strong>[Nome da Empresa]</strong>, uma sociedade constituída sob as leis do Brasil, com sede em <strong>[Endereço Completo]</strong>, registrada sob o CNPJ <strong>[Número do CNPJ]</strong>. Para quaisquer questões relacionadas a estes Termos e Condições, entre em contato conosco pelo e-mail <strong>[E-mail de Contato]</strong> ou pelo telefone <strong>[Número de Contato]</strong>.</p>
                    <br/>
                    <h2>2. Aceitação dos Termos</h2>
                    <p className='term_just'>Ao acessar ou utilizar o site <strong>[Nome do Site]</strong>, o usuário concorda em cumprir e ser vinculado por estes Termos e Condições, bem como pela nossa <a href="[URL Política de Privacidade]">Política de Privacidade</a> e <a href="[URL Política de Cookies]">Política de Cookies</a>, todas disponíveis de forma acessível no site. Caso não concorde com algum dos termos, o usuário deverá cessar imediatamente o uso do site e dos serviços.</p>
                    <br/>
                    <h2>3. Descrição dos Serviços</h2>
                    <p className='term_just'>A <strong>[Nome da Empresa]</strong> oferece produtos e serviços digitais, incluindo, mas não se limitando a <strong>[descrição dos produtos e serviços]</strong>, disponibilizados por meio deste site. O usuário poderá adquirir esses produtos de acordo com os procedimentos estabelecidos no site.</p>
                    <br/>
                    <h2>4. Privacidade e Proteção de Dados Pessoais</h2>
                    <p className='term_just'>De acordo com a <strong>Lei Geral de Proteção de Dados Pessoais (LGPD - Lei nº 13.709/2018)</strong>, a <strong>[Nome da Empresa]</strong> compromete-se a proteger os dados pessoais de seus usuários. Ao utilizar nosso site e fornecer seus dados, o usuário concorda com o tratamento de suas informações de acordo com os seguintes termos:</p>
                    <br/>
                    <h3>4.1 Coleta de Dados</h3>
                    <p className='term_just'>Coletamos e processamos dados pessoais dos usuários, incluindo:</p>
                    <ul>
                         <li className='term_just'><strong>Dados de cadastro</strong> (nome, endereço de e-mail, número de telefone, etc.);</li>
                         <li className='term_just'><strong>Dados de navegação</strong> (endereço IP, tipo de dispositivo, navegador, histórico de navegação);</li>
                         <li className='term_just'><strong>Dados de pagamento</strong>, quando aplicável.</li>
                    </ul>
                    <br/>
                    <h3>4.2 Finalidade do Tratamento</h3>
                    <p className='term_just'>Os dados pessoais coletados serão utilizados para:</p>
                    <ul>
                         <li className='term_just'>Processamento de compras e fornecimento de produtos e serviços;</li>
                         <li className='term_just'>Comunicação com o usuário (notificações, promoções, suporte técnico);</li>
                         <li className='term_just'>Melhoria da experiência do usuário e personalização do conteúdo;</li>
                         <li className='term_just'>Cumprimento de obrigações legais e regulatórias.</li>
                    </ul>
                    <br/>
                    <h3>4.3 Compartilhamento de Dados</h3>
                    <p className='term_just'>Os dados pessoais podem ser compartilhados com terceiros, tais como:</p>
                    <ul>
                         <li className='term_just'>Prestadores de serviços contratados para processamento de pagamentos, hospedagem e suporte técnico;</li>
                         <li className='term_just'>Autoridades competentes, quando exigido por lei;</li>
                         <li className='term_just'>Empresas do grupo <strong>[Nome da Empresa]</strong>, conforme aplicável.</li>
                    </ul>
                    <br/>
                    <h3>4.4 Segurança e Armazenamento dos Dados</h3>
                    <p className='term_just'>A <strong>[Nome da Empresa]</strong> adota medidas técnicas e organizacionais adequadas para garantir a segurança dos dados pessoais dos usuários. No entanto, a segurança absoluta na internet não pode ser garantida, e o usuário reconhece que fornece suas informações por sua própria conta e risco.</p>
                    <br/>
                    <h3>4.5 Direitos do Usuário</h3>
                    <p className='term_just'>Conforme a LGPD, o usuário tem o direito de:</p>
                    <ul>
                         <li className='term_just'>Acessar e corrigir seus dados pessoais;</li>
                         <li className='term_just'>Solicitar a exclusão de seus dados, quando aplicável;</li>
                         <li className='term_just'>Revogar o consentimento para o tratamento de seus dados pessoais;</li>
                         <li className='term_just'>Solicitar a portabilidade dos dados.</li>
                    </ul>
                    <p className='term_just'>Para exercer esses direitos, o usuário deverá entrar em contato com nosso <strong>Encarregado de Proteção de Dados (DPO)</strong> através de <strong>[E-mail do DPO]</strong>.</p>
                    <br/>
                    <h2>5. Política de Cookies</h2>
                    <p className='term_just'>Utilizamos cookies e tecnologias semelhantes para melhorar a experiência do usuário em nosso site. Os cookies permitem o armazenamento de informações de navegação e facilitam a personalização de conteúdo e anúncios.</p>
                    <br/>
                    <h3>5.1 Tipos de Cookies Utilizados</h3>
                    <ul>
                         <li className='term_just'><strong>Cookies Estritamente Necessários:</strong> Essenciais para o funcionamento do site e a execução de serviços solicitados pelo usuário;</li>
                         <li className='term_just'><strong>Cookies de Desempenho:</strong> Coletam informações sobre como os usuários utilizam o site, para melhorias de usabilidade;</li>
                         <li className='term_just'><strong>Cookies de Funcionalidade:</strong> Permitem que o site lembre-se de escolhas feitas pelo usuário (como nome de usuário, idioma, etc.);</li>
                         <li className='term_just'><strong>Cookies de Publicidade:</strong> Utilizados para exibir anúncios relevantes de acordo com os interesses do usuário.</li>
                    </ul>
                    <br/>
                    <h3>5.2 Gerenciamento de Cookies</h3>
                    <p className='term_just'>O usuário pode configurar seu navegador para recusar cookies ou alertá-lo quando estiverem sendo enviados. No entanto, algumas funcionalidades do site podem não funcionar corretamente sem a aceitação de cookies.</p>
                    <br/>
                    <h2>6. Responsabilidade</h2>
                    <p className='term_just'>A <strong>[Nome da Empresa]</strong> se esforça para garantir que as informações fornecidas no site sejam precisas e atualizadas. No entanto, o usuário reconhece que o uso do site e dos serviços é por sua conta e risco. A empresa não será responsável por quaisquer danos diretos, indiretos, incidentais, ou consequentes relacionados ao uso ou à incapacidade de uso do site.</p>
                    <br/>
                    <h2>7. Garantias</h2>
                    <p className='term_just'>Os produtos digitais oferecidos são fornecidos “no estado em que se encontram”, sem garantias expressas ou implícitas. A <strong>[Nome da Empresa]</strong> não garante que os produtos ou serviços atenderão às expectativas do usuário, ou que serão livres de erros.</p>
                    <br/>
                    <h2>8. Direito de Arrependimento</h2>
                    <p className='term_just'>Conforme o <strong>Código de Defesa do Consumidor</strong>, o usuário tem o direito de desistir da compra em até 7 (sete) dias corridos a partir da data de compra ou entrega do produto digital, desde que o produto não tenha sido acessado ou baixado. Para solicitar o cancelamento, entre em contato com nosso serviço de atendimento ao cliente através de <strong>[E-mail ou Telefone]</strong>.</p>
                    <br/>
                    <h2>9. Acesso de Assinantes e Segurança da Conta</h2>
                    <p className='term_just'>O uso dos produtos e serviços digitais fornecidos por <strong>[Nome da Empresa]</strong> é restrito ao assinante que se registrou no serviço e concordou com estes Termos e Condições. O acesso aos serviços é concedido exclusivamente ao assinante, sendo estritamente proibido o compartilhamento das credenciais da conta, incluindo nome de usuário e senha, com terceiros.</p>
                    <br/>
                    <h3>9.1 Uso Pessoal Exclusivo</h3>
                    <p className='term_just'>Os produtos e serviços oferecidos por <strong>[Nome da Empresa]</strong> são destinados exclusivamente ao uso pessoal do assinante. O assinante é responsável por garantir que as informações de sua conta, incluindo a senha, sejam mantidas confidenciais e seguras. Qualquer atividade realizada através da conta do assinante será considerada como tendo sido realizada pelo próprio assinante.</p>
                    <br/>
                    <h3>9.2 Proibição de Compartilhamento de Contas</h3>
                    <p className='term_just'>Os assinantes estão expressamente proibidos de compartilhar suas credenciais de acesso com terceiros, incluindo familiares, colegas ou amigos. Caso <strong>[Nome da Empresa]</strong> suspeite que as credenciais da conta foram compartilhadas, nos reservamos o direito de suspender ou encerrar a conta sem aviso prévio e sem reembolso.</p>
                    <br/>
                    <h3>9.3 Segurança da Conta</h3>
                    <p className='term_just'>Os assinantes devem notificar imediatamente <strong>[Nome da Empresa]</strong> sobre qualquer uso não autorizado de sua conta ou qualquer outra violação de segurança. A empresa não se responsabiliza por qualquer perda ou dano resultante da falha do assinante em proteger as informações de sua conta.</p>
                    <br/>
                    <h3>9.4 Monitoramento e Aplicação</h3>
                    <p className='term_just'><strong>[Nome da Empresa]</strong> reserva-se o direito de monitorar as contas para garantir o cumprimento destes Termos e Condições. A violação desta cláusula poderá resultar na suspensão, encerramento da conta ou em ação legal, dependendo da gravidade da infração.</p>
                    <br/>
                    <h2>10. Propriedade Intelectual</h2>
                    <p className='term_just'>Todos os conteúdos, marcas, logotipos, e outros materiais exibidos no site são de propriedade exclusiva da <strong>[Nome da Empresa]</strong> ou de seus licenciadores, sendo protegidos pelas leis de direitos autorais e propriedade intelectual. O usuário não pode reproduzir, distribuir ou criar obras derivadas sem o consentimento prévio e por escrito da empresa.</p>
                    <br/>
                    <h2>11. Modificações nos Termos e Condições</h2>
                    <p className='term_just'>A <strong>[Nome da Empresa]</strong> reserva-se o direito de modificar estes Termos e Condições a qualquer momento, a seu exclusivo critério. As alterações serão publicadas nesta página e, caso as modificações sejam significativas, o usuário será notificado por e-mail ou através de um aviso no site. O uso contínuo do site após as mudanças implica a aceitação dos novos termos.</p>
                    <br/>
                    <h2>12. Legislação Aplicável e Foro</h2>
                    <p className='term_just'>Estes Termos e Condições são regidos e interpretados de acordo com as leis da República Federativa do Brasil. Qualquer disputa ou controvérsia decorrente da utilização do site ou dos produtos e serviços será dirimida no foro da comarca de <strong>[Cidade]</strong>, com exclusão de qualquer outro.</p>
                    
               </>
          );

     }
     else if(language == "en-US"){
          return(
               <>
                    {/* <h2>Terms and Conditions of Use</h2> */}
                    <p><strong>Last updated:</strong> 07/09/2024</p>
                    <br/>
                    <h2>1. Company Identification</h2>
                    <p className='term_just'>This website is operated by <strong>[Company Name]</strong>, a company incorporated under the laws of Brazil, headquartered at <strong>[Full Address]</strong>, registered under CNPJ <strong>[CNPJ Number]</strong>. For any questions related to these Terms and Conditions, please contact us by email at <strong>[Contact Email]</strong> or by phone at <strong>[Contact Number]</strong>.</p>
                    <br/>
                    <h2>2. Acceptance of the Terms</h2>
                    <p className='term_just'>By accessing or using the <strong>[Website Name]</strong> website, the user agrees to comply with and be bound by these Terms and Conditions, as well as our <a href="[Privacy Policy URL]">Privacy Policy</a> and <a href="[Cookies Policy URL]">Cookies Policy</a>, all accessible on the website. If the user does not agree with any of the terms, they should immediately cease using the website and services.</p>
                    <br/>
                    <h2>3. Description of Services</h2>
                    <p className='term_just'><strong>[Company Name]</strong> offers digital products and services, including but not limited to <strong>[describe products such as software, courses, e-books, etc.]</strong>, available through this website. The user can purchase these products according to the procedures established on the website.</p>
                    <br/>
                    <h2>4. Privacy and Data Protection</h2>
                    <p className='term_just'>In accordance with the <strong>Brazilian General Data Protection Law (LGPD - Law No. 13.709/2018)</strong>, <strong>[Company Name]</strong> is committed to protecting the personal data of its users. By using our website and providing your data, the user agrees to the processing of their information according to the following terms:</p>
                    <br/>
                    <h3>4.1 Data Collection</h3>
                    <p className='term_just'>We collect and process users' personal data, including:</p>
                    <ul>
                         <li className='term_just'><strong>Registration data</strong> (name, email address, phone number, etc.);</li>
                         <li className='term_just'><strong>Browsing data</strong> (IP address, device type, browser, browsing history);</li>
                         <li className='term_just'><strong>Payment data</strong>, when applicable.</li>
                    </ul>
                    <br/>
                    <h3>4.2 Purpose of Processing</h3>
                    <p className='term_just'>The personal data collected will be used for:</p>
                    <ul>
                         <li className='term_just'>Processing purchases and providing products and services;</li>
                         <li className='term_just'>Communication with the user (notifications, promotions, technical support);</li>
                         <li className='term_just'>Improving user experience and personalizing content;</li>
                         <li className='term_just'>Compliance with legal and regulatory obligations.</li>
                    </ul>
                    <br/>
                    <h3>4.3 Data Sharing</h3>
                    <p className='term_just'>Personal data may be shared with third parties, such as:</p>
                    <ul>
                         <li className='term_just'>Service providers hired for payment processing, hosting, and technical support;</li>
                         <li className='term_just'>Competent authorities, when required by law;</li>
                         <li className='term_just'>Companies within the <strong>[Company Name]</strong> group, as applicable.</li>
                    </ul>
                    <br/>
                    <h3>4.4 Data Security and Storage</h3>
                    <p className='term_just'><strong>[Company Name]</strong> takes appropriate technical and organizational measures to ensure the security of users' personal data. However, absolute security on the internet cannot be guaranteed, and the user acknowledges that they provide their information at their own risk.</p>
                    <br/>
                    <h3>4.5 User Rights</h3>
                    <p className='term_just'>According to the LGPD, the user has the right to:</p>
                    <ul>
                         <li className='term_just'>Access and correct their personal data;</li>
                         <li className='term_just'>Request the deletion of their data, when applicable;</li>
                         <li className='term_just'>Revoke consent for the processing of their personal data;</li>
                         <li className='term_just'>Request data portability.</li>
                    </ul>
                    <p className='term_just'>To exercise these rights, the user should contact our <strong>Data Protection Officer (DPO)</strong> at <strong>[DPO Email]</strong>.</p>
                    <br/>
                    <h2>5. Cookies Policy</h2>
                    <p className='term_just'>We use cookies and similar technologies to enhance the user experience on our website. Cookies allow the storage of browsing information and facilitate content and advertisement personalization.</p>
                    <br/>
                    <h3>5.1 Types of Cookies Used</h3>
                    <ul>
                         <li className='term_just'><strong>Strictly Necessary Cookies:</strong> Essential for the website's operation and the execution of services requested by the user;</li>
                         <li className='term_just'><strong>Performance Cookies:</strong> Collect information on how users use the website to improve usability;</li>
                         <li className='term_just'><strong>Functionality Cookies:</strong> Allow the website to remember user choices (such as username, language, etc.);</li>
                         <li className='term_just'><strong>Advertising Cookies:</strong> Used to display relevant ads based on the user's interests.</li>
                    </ul>
                    <br/>
                    <h3>5.2 Managing Cookies</h3>
                    <p className='term_just'>The user can configure their browser to refuse cookies or notify them when cookies are being sent. However, some website features may not function correctly without accepting cookies.</p>
                    <br/>
                    <h2>6. Liability</h2>
                    <p className='term_just'><strong>[Company Name]</strong> strives to ensure that the information provided on the website is accurate and up-to-date. However, the user acknowledges that the use of the website and services is at their own risk. The company will not be liable for any direct, indirect, incidental, or consequential damages related to the use or inability to use the website.</p>
                    <br/>
                    <h2>7. Warranties</h2>
                    <p className='term_just'>The digital products offered are provided "as is," without express or implied warranties. <strong>[Company Name]</strong> does not guarantee that the products or services will meet the user's expectations or be error-free.</p>
                    <br/>
                    <h2>8. Right of Withdrawal</h2>
                    <p className='term_just'>According to the <strong>Consumer Protection Code</strong>, the user has the right to withdraw from the purchase within 7 (seven) calendar days from the date of purchase or delivery of the digital product, provided that the product has not been accessed or downloaded. To request a cancellation, contact our customer service at <strong>[Email or Phone]</strong>.</p>
                    <br/>
                    <h2>9. Subscriber Access and Account Security</h2>
                    <p className='term_just'>The use of digital products and services provided by <strong>[Company Name]</strong> is restricted to the subscriber who has registered for the service and agreed to these Terms and Conditions. Access to the services is granted solely to the subscriber, and the sharing of account credentials, including username and password, with third parties is strictly prohibited.</p>
                    <br/>
                    <h3>9.1 Personal Use Only</h3>
                    <p className='term_just'>The products and services offered by <strong>[Company Name]</strong> are intended for the subscriber’s personal use only. The subscriber is responsible for ensuring that their account details, including password, are kept confidential and secure. Any activity conducted through the subscriber’s account will be considered to have been performed by the subscriber.</p>
                    <br/>
                    <h3>9.2 Prohibited Account Sharing</h3>
                    <p className='term_just'>Subscribers are expressly forbidden from sharing their login credentials with any third parties, including family members, colleagues, or friends. If <strong>[Company Name]</strong> suspects that account credentials have been shared, we reserve the right to suspend or terminate the account without notice and without refund.</p>
                    <br/>
                    <h3>9.3 Account Security</h3>
                    <p className='term_just'>Subscribers are required to immediately notify <strong>[Company Name]</strong> of any unauthorized use of their account or any other breach of security. The company is not responsible for any loss or damage resulting from the failure of the subscriber to protect their account information.</p>
                    <br/>
                    <h3>9.4 Monitoring and Enforcement</h3>
                    <p className='term_just'><strong>[Company Name]</strong> reserves the right to monitor accounts to ensure compliance with these Terms and Conditions. Violation of this clause may result in account suspension, termination, or legal action, depending on the severity of the breach.</p>
                    <h2>10. Intellectual Property</h2>
                    <p className='term_just'>All content, trademarks, logos, and other materials displayed on the website are the exclusive property of <strong>[Company Name]</strong> or its licensors, protected by copyright and intellectual property laws. The user may not reproduce, distribute, or create derivative works without prior written consent from the company.</p>
                    <br/>
                    <h2>11. Changes to the Terms and Conditions</h2>
                    <p className='term_just'><strong>[Company Name]</strong> reserves the right to modify these Terms and Conditions at any time, at its sole discretion. Changes will be posted on this page, and if the changes are significant, the user will be notified by email or through a notice on the website. Continued use of the website after the changes implies acceptance of the new terms.</p>
                    <br/>
                    <h2>12. Governing Law and Jurisdiction</h2>
                    <p className='term_just'>These Terms and Conditions are governed by and interpreted in accordance with the laws of the Federative Republic of Brazil. Any dispute or controversy arising from the use of the website or the products and services will be settled in the court of the jurisdiction of <strong>[City]</strong>, with the exclusion of any other court.</p>
               </>
          )
     }
     else if(language == "es"){
          return(
               <>
                    {/* <h2>Términos y Condiciones de Uso</h2> */}
                    <p><strong>Última actualización:</strong> 07/09/2024</p>
                    <br/>
                    <h2>1. Identificación de la Empresa</h2>
                    <p className='term_just'>Este sitio web es operado por <strong>[Nombre de la Empresa]</strong>, una empresa constituida bajo las leyes de Brasil, con sede en <strong>[Dirección Completa]</strong>, registrada bajo el CNPJ <strong>[Número del CNPJ]</strong>. Para cualquier consulta relacionada con estos Términos y Condiciones, póngase en contacto con nosotros por correo electrónico a <strong>[Correo Electrónico de Contacto]</strong> o por teléfono al <strong>[Número de Contacto]</strong>.</p>
                    <br/>
                    <h2>2. Aceptación de los Términos</h2>
                    <p className='term_just'>Al acceder o utilizar el sitio web <strong>[Nombre del Sitio]</strong>, el usuario acepta cumplir y estar sujeto a estos Términos y Condiciones, así como a nuestra <a href="[URL de Política de Privacidad]">Política de Privacidad</a> y <a href="[URL de Política de Cookies]">Política de Cookies</a>, todas accesibles en el sitio web. Si el usuario no está de acuerdo con alguno de los términos, deberá dejar de utilizar el sitio y los servicios de inmediato.</p>
                    <br/>
                    <h2>3. Descripción de los Servicios</h2>
                    <p className='term_just'><strong>[Nombre de la Empresa]</strong> ofrece productos y servicios digitales, incluidos, entre otros, <strong>[descripción de productos como software, cursos, libros electrónicos, etc.]</strong>, disponibles a través de este sitio web. El usuario podrá adquirir estos productos de acuerdo con los procedimientos establecidos en el sitio web.</p>
                    <br/>
                    <h2>4. Privacidad y Protección de Datos</h2>
                    <p className='term_just'>De acuerdo con la <strong>Lei Geral de Proteção de Dados (LGPD - Ley Nº 13.709/2018)</strong>, <strong>[Nombre de la Empresa]</strong> se compromete a proteger los datos personales de sus usuarios. Al utilizar nuestro sitio web y proporcionar sus datos, el usuario acepta el procesamiento de su información de acuerdo con los siguientes términos:</p>
                    <br/>
                    <h3>4.1 Recopilación de Datos</h3>
                    <p className='term_just'>Recopilamos y procesamos datos personales de los usuarios, que incluyen:</p>
                    <ul>
                         <li className='term_just'><strong>Datos de registro</strong> (nombre, dirección de correo electrónico, número de teléfono, etc.);</li>
                         <li className='term_just'><strong>Datos de navegación</strong> (dirección IP, tipo de dispositivo, navegador, historial de navegación);</li>
                         <li className='term_just'><strong>Datos de pago</strong>, cuando corresponda.</li>
                    </ul>
                    <br/>
                    <h3>4.2 Finalidad del Tratamiento</h3>
                    <p>Los datos personales recopilados se utilizarán para:</p>
                    <ul>
                         <li>Procesar compras y proporcionar productos y servicios;</li>
                         <li>Comunicarse con el usuario (notificaciones, promociones, soporte técnico);</li>
                         <li>Mejorar la experiencia del usuario y personalizar el contenido;</li>
                         <li>Cumplir con las obligaciones legales y regulatorias.</li>
                    </ul>
                    <br/>
                    <h3>4.3 Compartición de Datos</h3>
                    <p className='term_just'>Los datos personales pueden compartirse con terceros, como:</p>
                    <ul>
                         <li className='term_just'>Proveedores de servicios contratados para el procesamiento de pagos, alojamiento y soporte técnico;</li>
                         <li className='term_just'>Autoridades competentes, cuando sea requerido por ley;</li>
                         <li className='term_just'>Empresas del grupo <strong>[Nombre de la Empresa]</strong>, cuando corresponda.</li>
                    </ul>
                    <br/>
                    <h3>4.4 Seguridad y Almacenamiento de los Datos</h3>
                    <p className='term_just'><strong>[Nombre de la Empresa]</strong> adopta medidas técnicas y organizativas adecuadas para garantizar la seguridad de los datos personales de los usuarios. Sin embargo, la seguridad absoluta en internet no puede garantizarse, y el usuario reconoce que proporciona su información bajo su propio riesgo.</p>
                    <br/>
                    <h3>4.5 Derechos del Usuario</h3>
                    <p>Según la LGPD, el usuario tiene derecho a:</p>
                    <ul>
                         <li className='term_just'>Acceder y corregir sus datos personales;</li>
                         <li className='term_just'>Solicitar la eliminación de sus datos, cuando corresponda;</li>
                         <li className='term_just'>Revocar el consentimiento para el procesamiento de sus datos personales;</li>
                         <li className='term_just'>Solicitar la portabilidad de los datos.</li>
                    </ul>
                    <p className='term_just'>Para ejercer estos derechos, el usuario deberá ponerse en contacto con nuestro <strong>Responsable de Protección de Datos (DPO)</strong> a través de <strong>[Correo Electrónico del DPO]</strong>.</p>
                    <br/>
                    <h2>5. Política de Cookies</h2>
                    <p className='term_just'>Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario en nuestro sitio web. Las cookies permiten almacenar información de navegación y facilitar la personalización de contenido y anuncios.</p>
                    <br/>
                    <h3>5.1 Tipos de Cookies Utilizados</h3>
                    <ul>
                         <li className='term_just'><strong>Cookies Estrictamente Necesarias:</strong> Esenciales para el funcionamiento del sitio web y la ejecución de servicios solicitados por el usuario;</li>
                         <li className='term_just'><strong>Cookies de Rendimiento:</strong> Recopilan información sobre cómo los usuarios utilizan el sitio web, para mejorar la usabilidad;</li>
                         <li className='term_just'><strong>Cookies de Funcionalidad:</strong> Permiten que el sitio web recuerde las opciones hechas por el usuario (como el nombre de usuario, idioma, etc.);</li>
                         <li className='term_just'><strong>Cookies de Publicidad:</strong> Utilizadas para mostrar anuncios relevantes según los intereses del usuario.</li>
                    </ul>
                    <br/>
                    <h3>5.2 Gestión de Cookies</h3>
                    <p className='term_just'>El usuario puede configurar su navegador para rechazar cookies o alertarlo cuando se estén enviando. Sin embargo, algunas funcionalidades del sitio pueden no funcionar correctamente sin aceptar cookies.</p>
                    <br/>
                    <h2>6. Responsabilidad</h2>
                    <p className='term_just'><strong>[Nombre de la Empresa]</strong> se esfuerza por garantizar que la información proporcionada en el sitio web sea precisa y esté actualizada. Sin embargo, el usuario reconoce que el uso del sitio web y los servicios es bajo su propio riesgo. La empresa no será responsable por ningún daño directo, indirecto, incidental o consecuente relacionado con el uso o la imposibilidad de uso del sitio web.</p>
                    <br/>
                    <h2>7. Garantías</h2>
                    <p className='term_just'>Los productos digitales ofrecidos se proporcionan "tal como están", sin garantías expresas o implícitas. <strong>[Nombre de la Empresa]</strong> no garantiza que los productos o servicios cumplan con las expectativas del usuario o que estén libres de errores.</p>
                    <br/>
                    <h2>8. Derecho de Desistimiento</h2>
                    <p className='term_just'>De acuerdo con el <strong>Código de Protección al Consumidor</strong>, el usuario tiene derecho a desistir de la compra dentro de los 7 (siete) días calendario a partir de la fecha de compra o entrega del producto digital, siempre que el producto no haya sido accedido o descargado. Para solicitar la cancelación, comuníquese con nuestro servicio de atención al cliente a través de <strong>[Correo Electrónico o Teléfono]</strong>.</p>
                    <br/>
                    <h2>9. Acceso de Suscriptores y Seguridad de la Cuenta</h2>
                    <p className='term_just'>El uso de los productos y servicios digitales proporcionados por <strong>[Nombre de la Empresa]</strong> está restringido al suscriptor que se haya registrado para el servicio y aceptado estos Términos y Condiciones. El acceso a los servicios se otorga exclusivamente al suscriptor, y está estrictamente prohibido compartir las credenciales de la cuenta, incluido el nombre de usuario y la contraseña, con terceros.</p>
                    <br/>
                    <h3>9.1 Uso Personal Exclusivo</h3>
                    <p className='term_just'>Los productos y servicios ofrecidos por <strong>[Nombre de la Empresa]</strong> están destinados exclusivamente para el uso personal del suscriptor. El suscriptor es responsable de garantizar que los detalles de su cuenta, incluida la contraseña, se mantengan confidenciales y seguros. Cualquier actividad realizada a través de la cuenta del suscriptor se considerará realizada por el propio suscriptor.</p>
                    <br/>
                    <h3>9.2 Prohibición de Compartir Cuentas</h3>
                    <p className='term_just'>Se prohíbe expresamente a los suscriptores compartir sus credenciales de inicio de sesión con terceros, incluidos familiares, colegas o amigos. Si <strong>[Nombre de la Empresa]</strong> sospecha que las credenciales de la cuenta han sido compartidas, nos reservamos el derecho de suspender o cancelar la cuenta sin previo aviso y sin reembolso.</p>
                    <br/>
                    <h3>9.3 Seguridad de la Cuenta</h3>
                    <p className='term_just'>Los suscriptores deben notificar inmediatamente a <strong>[Nombre de la Empresa]</strong> sobre cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad. La empresa no se hace responsable de ninguna pérdida o daño resultante de la falta de protección de la información de la cuenta por parte del suscriptor.</p>
                    <br/>
                    <h3>9.4 Monitoreo y Cumplimiento</h3>
                    <p className='term_just'><strong>[Nombre de la Empresa]</strong> se reserva el derecho de monitorear las cuentas para garantizar el cumplimiento de estos Términos y Condiciones. La violación de esta cláusula puede resultar en la suspensión, cancelación de la cuenta, o en acciones legales, dependiendo de la gravedad de la infracción.</p>
                    <h2>10. Propiedad Intelectual</h2>
                    <p className='term_just'>Todo el contenido, marcas comerciales, logotipos y otros materiales mostrados en el sitio web son propiedad exclusiva de <strong>[Nombre de la Empresa]</strong> o sus licenciantes, y están protegidos por las leyes de derechos de autor y propiedad intelectual. El usuario no puede reproducir, distribuir o crear obras derivadas sin el consentimiento previo por escrito de la empresa.</p>
                    <br/>
                    <h2>11. Modificaciones de los Términos y Condiciones</h2>
                    <p className='term_just'><strong>[Nombre de la Empresa]</strong> se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento, a su exclusivo criterio. Los cambios se publicarán en esta página y, si los cambios son significativos, se notificará al usuario por correo electrónico o mediante un aviso en el sitio web. El uso continuo del sitio web después de los cambios implica la aceptación de los nuevos términos.</p>
                    <br/>
                    <h2>12. Legislación Aplicable y Jurisdicción</h2>
                    <p className='term_just'>Estos Términos y Condiciones se rigen e interpretan de acuerdo con las leyes de la República Federativa de Brasil. Cualquier disputa o controversia que surja del uso del sitio web o de los productos y servicios se resolverá en los tribunales de la jurisdicción de <strong>[Ciudad]</strong>, con exclusión de cualquier otro tribunal.</p>
               </>
          )
     }

	
    
};

export default Terms;