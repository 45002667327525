import React from 'react';
import { Link } from 'react-router-dom';
import { Space  } from 'antd';
import '../styles/card.css';
import { useTranslation } from "react-i18next";

const Card = ({title, image, text, route}) => {

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return (
		<div className="card" style={{width: (290), padding: 5}}>
			<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString()+route}>
				<img src={image} alt="Card" style={{width: (280)}}/>
				<div className="container">
					<Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
						<h3 style={{color: 'black'}}><b>{title}</b></h3> 
					</Space>
					<p>{text}</p> 
				</div>
			</Link>
		</div>
	);
    
};

export default Card;