import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const RequireAuth = ({ allowedRoles }) => {
    const { auth, habRoles } = useAuth();
    const location = useLocation();


    // const decoded = auth?.info !== undefined
    //     ? jwtDecode(auth?.info)
    //     : undefined;

    // let roles = [];
    // let jsonInfo = decoded?.info || "[]";
    // jsonInfo = JSON.parse(jsonInfo);

    // console.log(decoded);

    // if(jsonInfo.length > 0){
    //     roles = jsonInfo[0]?.roles || [];
    // }

    //const roles = decoded?.roles !== undefined ?  decoded?.roles : [];
    //console.log(roles);
    //console.log(allowedRoles);

    return (
        auth?.user
            ? habRoles(allowedRoles) || allowedRoles?.length === 0//roles.find(role => allowedRoles?.includes(role)) || allowedRoles?.length === 0
            //? auth?.roles?.find(role => allowedRoles?.includes(role)) || allowedRoles?.length === 0
                ? <Outlet />//<Navigate to="/not_found"/>//
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location, search: window.location.search }} replace />
    );
}

export default RequireAuth;