import React, { useEffect, useState, Component, useMemo, useCallback, useTransition } from 'react';
import { Flex, Result } from 'antd';
import Terms from "../components/Terms";
import { useTranslation } from "react-i18next";

const Home = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return (
		<div>
			<h2 className="title is-1">{t('termos_condicoes')}</h2>
			<br />
			<hr />
			<br />
			<Flex justify={'center'} align={'center'}>
				<div style={{maxWidth:900}}><Terms style={{maxWidth:900}}/></div>
			</Flex>
		</div>
	)

};

export default Home