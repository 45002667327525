import React, { useRef, useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
//import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';

const ResgatarCupom = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const { setAuth, auth, persist, setPersist } = useAuth();
	const errRef = useRef();
	const cupomRef = useRef();

	useEffect(() => {
		cupomRef.current.focus();
	 }, []);

	const [form] = Form.useForm();
	const [cupom, setCupom] = useState('');

	const [validCupom, setValidCupom] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const CP_REGEX = /^(?=.*[A-Z])(?=.*[0-9]).{8,15}$/;

	useEffect(() => {
		setErrMsg('');
		setValidCupom(CP_REGEX.test(cupom));
	}, [cupom])

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);

		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);

		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = CP_REGEX.test(cupom);

		if (!v1) {
			setErrMsg("Cupom inválido!");
			return;
		}

		try {
			const response = await axiosPrivate.post('/SetCupom', { em : auth?.user, cp: cupom});

			if (response.status === 200) {
				//console.log(response.data);
				if (response.data !== null && response.data !== undefined && response.data !== "") {
		
					switch(response.data.error_code) {
						case 0:

							//console.log('retorno OK');
							//setSuccess(true);
							//form.resetFields();
							//setErrMsg('');
							//setAuth(return_info[0]);
							//setAuth({ user: user, info: response.data, menu_info: menu_info });
							//setAuth({ user: response.data.user, access_token: response.data.access_token, menu_info: response.data.menu_info })
							
							//navigate('/');
							//console.log(window.location.origin.toString() + '/minhas_assinaturas');
							navigate('/minhas_assinaturas', {replace: true});
							break;
							//sucRef.current.focus();
						case 1:
							setErrMsg('resgatar_cupom_msg.error_1');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('resgatar_cupom_msg.error_2');
							//setSuccess(false);
							break;
						case 3:
							setErrMsg('resgatar_cupom_msg.error_3');
							//setSuccess(false);
							break;
						default:
							setErrMsg('resgatar_cupom_msg.error_4');
							//setSuccess(false);
					 }

				}else{
					setErrMsg('resgatar_cupom_msg.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('resgatar_cupom_msg.error_6');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('resgatar_cupom_msg.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('resgatar_cupom_msg.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('resgatar_cupom_msg.error_9');
				//setSuccess(false);
			}
		}
	}

	return (
		<div>
			<h2 className="title is-1">{t('resgatar_cupom')}</h2>
			<br />
			<hr />
			<br />
			<div
				style={{
					//height: 300, 
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<Form
					form={form}
					style={{
						maxWidth: 320,
					}}
					scrollToFirstError
					name="normal_login"
					initialValues={{
						remember: true,
					}}
				//onFinish={onFinish}
				>

					{errMsg !== "" ? (
						<><Alert ref={errRef} message={t(errMsg)} type="error" showIcon /><br /></>
					) : (<></>)}

					<Form.Item
						name="email"
						label={t('resgatar_cupom_msg.cupom')}
						onChange={(e) => setCupom(e.target.value)}
						maxLength={15}
						rules={[
							{
								required: true,
								type: 'string',
								message: t('resgatar_cupom_msg.cupom_invalido'),
							},
							() => ({
								validator(_, value) {
								if (CP_REGEX.test(cupom)) {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("resgatar_cupom_msg.cupom_invalido")));
								},
							}),
							// {
							// 	required: true,
							// 	message: 'Por favor, digite um e-mail',
							// },
						]}
					>
						<Input ref={cupomRef}/>
					</Form.Item>

					<Form.Item>
						<SubmitButton disabled={!validCupom ? true : false} form={form} on_click={handleSubmit}>{t('resgatar_cupom_msg.resgatar')}</SubmitButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
};

export default ResgatarCupom