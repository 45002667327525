import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Layout, Menu, Space, theme, Flex, Select, Col, Row, Drawer } from 'antd';
import { UserOutlined, CaretDownOutlined } from '@ant-design/icons';
//import '../styles/navbar.css'
import { MenuOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';

const Navbar = () => {

	const { auth } = useAuth();
	const logout = useLogout();

	const signOut = async () => {
		await logout();
	}

	const [showNavbar, setShowNavbar] = useState(false)
	//const [collapsed, setCollapsed] = useState(false)

	const handleShowNavbar = () => {
		setShowNavbar(!showNavbar)
	}

	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const [currentLanguage, setCurrentLanguage] = useState(language);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
		changeLanguage(value);
	};

	function myFunction() {
		var x = document.getElementById("myTopnav");
		if (x.className === "topnav") {
			x.className += " responsive";
		} else {
			x.className = "topnav";
		}
	}

	function myFunction2() {
		document.getElementById("myDropdown").classList.toggle("show");
		alert('teste');
	}

	/*window.onclick = function(event) {
	  if (!event.target.matches('.dropbtn')) {
	    var dropdowns = document.getElementsByClassName("dropdown-content");
	    var i;
	    for (i = 0; i < dropdowns.length; i++) {
		 var openDropdown = dropdowns[i];
		 if (openDropdown.classList.contains('show')) {
		   openDropdown.classList.remove('show');
		 }
	    }
	  }
	}*/

	const items = [
		// {
		// 	key: '00',
		// 	label: (<a target="_self" rel="noopener noreferrer" href={window.location.origin.toString()}
		// 		style={{
		// 			minWidth: 180
		// 		}}>
		// 		<h3 style={{
		// 			color: '#006EDF',
		// 			//paddingLeft: 10,
		// 			//float:'left',
		// 			marginLeft: 10,
		// 			width: 180,
		// 			minWidth: 180
		// 		}}>TLS Engenharia</h3>
		// 	</a>)
		// },
		// {
		// 	key: '7',
		// 	label: language === 'en-US' ? (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/us.png" alt="USA" style={{ width: 32 }} /></Flex>) : language === 'es' ? (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/es.png" alt="ES" style={{ width: (32) }} /></Flex>) : (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/br.png" alt="BR" style={{ width: (32) }} /></Flex>),
		// 	children: [
		// 		{
		// 			key: '8',
		// 			label: (<div onClick={() => { changeLanguage("pt-BR"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/br.png" alt="BR" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>Português</p></Flex></div>)
		// 		},
		// 		{
		// 			key: '9',
		// 			label: (<div onClick={() => { changeLanguage("en-US"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/us.png" alt="USA" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>English</p></Flex></div>)
		// 		},
		// 		{
		// 			key: '10',
		// 			label: (<div onClick={() => { changeLanguage("es"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/es.png" alt="ES" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>Español</p></Flex></div>)
		// 		}
		// 	]
		// },
		{
			key: '5',
			label: (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/assinaturas'}>{t('assinaturas')}</Link>)
		},
		{
			key: '6',
			label: (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/contato'}>{t('contato')}</Link>)
		},
		{
			key: '7',
			label: (<><UserOutlined /> {t('acesso')} </>),
			children: [
				{
					key: '8',
					label: auth?.user && auth?.user !== "" ? (<a onClick={signOut}>{t('logout')}</a>) : (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/login'}>{t('login')}</Link>)
				},
				{
					key: '9',
					label: auth?.user && auth?.user !== "" ? (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/cadastro_info'}>{t('cadastro_msg.cadastro_info')}</Link>) : (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/cadastro'}>{t('cadastro')}</Link>)
				},

			]
		},

	];

	if (auth?.user && auth?.user !== "") {
		//Menus de usuário
		items[2]?.children?.push({
			key: '10',
			label: (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/minhas_assinaturas'} >{t('minhas_assinaturas')}</Link>),
		})
		items[2]?.children?.push({
			key: '11',
			label: (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/resgatar_cupom'} >{t('resgatar_cupom')}</Link>),
		})
	}

	if (auth?.user && auth?.user !== "") {
		//Menus de usuário
		items[2]?.children?.push({
			key: '12',
			label: (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/admin'} >{t('admin')}</Link>),
		})
	}

	return (
		<div className="topnav" id="myTopnav" style={{ backgroundColor: '#FFFFFF', width: '100%', position: 'fixed', maxHeight: 65, zIndex: 1000, borderBottom:'1px solid #006EDF' }}>
			<Flex vertical={false}>
				<Flex vertical={false} >
					<Menu
						theme="light"
						mode="horizontal"//{(collapsed == true) ? "horizontal" : 'inline'}
						defaultSelectedKeys={[]}
						items={[
							{
								key: '1',
								label: language === 'en-US' ? (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/us.png" alt="USA" style={{ width: 32 }} /><CaretDownOutlined /></Flex>) : language === 'es' ? (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/es.png" alt="ES" style={{ width: (32) }} /><CaretDownOutlined /></Flex>) : (<Flex style={{ marginTop: 15 }} justify={'center'} align={'center'}><img src="../img/flags/br.png" alt="BR" style={{ width: (32) }} /><CaretDownOutlined /></Flex>),
								children: [
									{
										key: '2',
										label: (<div onClick={() => { changeLanguage("pt-BR"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/br.png" alt="BR" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>Português</p></Flex></div>)
									},
									{
										key: '3',
										label: (<div onClick={() => { changeLanguage("en-US"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/us.png" alt="USA" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>English</p></Flex></div>)
									},
									{
										key: '4',
										label: (<div onClick={() => { changeLanguage("es"); }}><Flex justify={'initial'} align={'center'}><img src="../img/flags/es.png" alt="ES" style={{ width: (32) }} /><p style={{ marginLeft: 5 }}>Español</p></Flex></div>)
									}
								]
							},
						]}
					/>
					<a target="_self" rel="noopener noreferrer" href={window.location.origin.toString()}
						style={{
							minWidth: 180
						}}>
						<h3 style={{
							color: '#006EDF',
							//paddingLeft: 10,
							float: 'left',
							//marginLeft: 10,
							width: 180,
							minWidth: 180
						}}>TLS Engenharia</h3>
					</a>
				</Flex>
				<div style={{ width: '100%'}}></div>
				<Flex justify='flex-end' style={{ right: 0, width: '30%', marginRight: 0, float: 'right' }}>
					<Menu
						theme="light"
						mode="horizontal"//{(collapsed == true) ? "horizontal" : 'inline'}
						defaultSelectedKeys={[]}
						items={items}
						style={{
							//position: 'fixed',
							right: 0,
							flex: 1,
							minWidth: 0,
							marginRight: 0,
							overflow: 'hidden',
							width: '100%',
							float: 'right'
						}}
						overflowedIndicator={<MenuOutlined style={{ fontSize: '18px' }} />}
					/>
				</Flex>
			</Flex>

			{/* <Menu
				theme="light"
				mode="horizontal"//{(collapsed == true) ? "horizontal" : 'inline'}
				defaultSelectedKeys={[]}
				items={items}
				style={{
					//position: 'fixed',
					//right: 0,
					flex: 1,
					minWidth: 0,
					overflow: 'hidden',
					width: '100%',
					//float: 'right'
				}}
				overflowedIndicator={<MenuOutlined style={{ fontSize: '18px' }} />}
			/> */}

		</div>
	)
}

export default Navbar