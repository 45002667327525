//import axios from '../api/axios';
import { axiosPrivate } from "../services/api";
import useAuth from '../hooks/useAuth';
import { jwtDecode } from "jwt-decode";
import Cookies from 'universal-cookie';

const useRefreshToken = () => {
    const { auth, setAuth, getSessionId } = useAuth();

    const refresh = async () => {

        try {

            /*
            
                const cookieOptions = {
                secure: true,
                httpOnly: true,
                sameSite: 'trict',
                expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 dias
                };

                document.cookie = `token=${token}; ${Object.keys(cookieOptions).map((key) => `${key}=${cookieOptions[key]}`).join('; ')}`;
            
            
            const token = 'abcd';
            const encryptedToken = token;
            const cookies4 = new Cookies();
            cookies4.set('token', encryptedToken, {
                secure: true,
                httpOnly: true,
                sameSite: 'Strict',
                expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
            });
            */

            /*const cookies3 = new Cookies();
            const checkToken = cookies3.get("token");
            console.log('new token',checkToken);

            const cookie = document.cookie.match(/token=([^;]*)/);
            console.log('cookie',cookie);
            if (cookie) {
                //setToken(cookie[1]);
                const tokenData = JSON.parse(atob(cookie[1].split('.')[1]));
                const expiration = new Date(tokenData.exp * 1000);
                if (expiration > new Date()) {
                    //setUser(tokenData.user);
                    console.log('tokenData',tokenData);
                } else {
                    //logout();
                    console.log('logout tokenData',tokenData);
                }
            }else{
                console.log('sem cookie');
                const token = 'abc';
                const expires = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
                document.cookie = `token=${token}; Secure; HttpOnly; SameSite=Strict; Expires=${expires.toUTCString()}`;
            }*/

            //const response = await axiosPrivate.post('/PageInfoRefresh', { em: 'thiagolemedasilva@hotmail.com'/*auth?.user*/ });
            //const response = await axiosPrivate.post('/PageInfoRefresh', { em: auth?.user !== undefined ? auth?.user : "" });
            //console.log('Refresh token',auth);
            const response = await axiosPrivate.post('/PageInfoRefresh', {si : getSessionId()});

            if (response.status === 200) {

                if (response.data !== null && response.data !== undefined && response.data !== "") {

                    try {
                        //console.log('headers', response);

                        //console.log('Refresh token2');
                        // const token = response.data;
                        // const tokenData = JSON.parse(atob(token.split('.')[1]));
                        // //const expires = new Date(Date.now() + cookieExpiration * 24 * 60 * 60 * 1000);
                        // const expires = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
                        // document.cookie = `token=${token}; Secure; HttpOnly; SameSite=Strict; Expires=${expires.toUTCString()}`;

                        // const decoded = jwtDecode(token);

                        // let user = "";
                        // let menu_info = [];
                        // let jsonInfo = decoded?.info || "[]";
                        // jsonInfo = JSON.parse(jsonInfo);

                        // if (jsonInfo.length > 0) {
                        //     user = jsonInfo[0]?.user || "";
                        //     menu_info = jsonInfo[0]?.menu_info || [];
                        // }

                        //setAuth({ user : user, info : response.data, menu_info : menu_info});
                        //setAuth({ user: user, menu_info: menu_info });
                        setAuth({ user: response.data.user, access_token: response.data.access_token, menu_info: response.data.menu_info });
                    } catch (e) {
                        setAuth({ user: "", access_token: "", menu_info: [] });
                    }

                }else{
                    console.log('headers', response);
                }
            } else {
                setAuth({ user: "", access_token: "", menu_info: [] });
            }

        } catch (err) {
            console.log('Erro ao carregar informações');
        }

    }
    return refresh;
};

export default useRefreshToken;
