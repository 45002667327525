import { 
    MenuFoldOutlined,
    MenuUnfoldOutlined, 
    ShopOutlined,
    DownOutlined, 
    UserOutlined,
    AppstoreOutlined,
    ContainerOutlined,
    DesktopOutlined,
    MailOutlined,
    PieChartOutlined,
    SettingOutlined
  } from '@ant-design/icons';

const StringIco = ({str}) => {
    switch(str) {
        case "MenuFoldOutlined": return(<MenuFoldOutlined />);
        case "MenuUnfoldOutlined": return(<MenuUnfoldOutlined />);
        case "ShopOutlined": return(<ShopOutlined />);
        case "DownOutlined": return(<DownOutlined />);
        case "UserOutlined": return(<UserOutlined />);
        case "AppstoreOutlined": return(<AppstoreOutlined />);
        case "ContainerOutlined": return(<ContainerOutlined />);
        case "DesktopOutlined": return(<DesktopOutlined />);
        case "SettingOutlined": return(<SettingOutlined />);
        case "MailOutlined": return(<MailOutlined />);
        case "PieChartOutlined": return(<PieChartOutlined />);
        default: return(<></>);
    }
}

export default StringIco;