import React, { useState, useEffect } from 'react';
import { Layout, Menu, Result } from 'antd';
import { UserOutlined } from '@ant-design/icons';
//import '../styles/sider.css';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const { Sider } = Layout;


const getLevelKeys = (items1) => {

	items1 = items1 !== undefined ? items1 : [];

	const key = {};
	const func = (items2, level = 1) => {
		items2.forEach((item) => {
			if (item.key) {
				key[item.key] = level;
			}
			if (item.children) {
				func(item.children, level + 1);
			}
		});
	};
	func(items1);
	return key;
};

const SideMenu = ({ side_menu }) => {

	side_menu = side_menu !== undefined ? side_menu : [];
	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const levelKeys = getLevelKeys(side_menu);
	const [stateOpenKeys, setStateOpenKeys] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [broken, setBroken] = useState(null);

	const location = useLocation();
	const { hash, pathname, search } = location;

	let sel_key = '';
	side_menu.map((items, index) => {
		if (items.children !== null && items.children !== undefined) {
			for (let i = 0; i < items.children.length; i++) {
				if (items.children[i].route === pathname) {
					sel_key = items.children[i].key;
				}
			}
		}
	});

	const onOpenChange = (openKeys) => {
		const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
		// open
		if (currentOpenKey !== undefined) {
			const repeatIndex = openKeys
				.filter((key) => key !== currentOpenKey)
				.findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
			setStateOpenKeys(
				openKeys
					// remove repeat key
					.filter((_, index) => index !== repeatIndex)
					// remove current level all child
					.filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
			);
		} else {
			// close
			setStateOpenKeys(openKeys);
		}
	};

	const onMenuClick = () => {
		setCollapsed(() => {
			return (broken == true) ? true : false;
		});
	}

	return (
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			width="260"
			height="100%"
			style={{
				//overflow: 'auto',
				//height: '100vh',
				//position: 'fixed',
				zIndex: 1,
				left: 0,
				top: 65,
				bottom: 0,
			}}
			collapsed={collapsed}
		onBreakpoint={(broken) => {
			//console.log('broken',broken);
			setBroken(broken);
			setCollapsed(broken);
		}}
		onCollapse={(collapsed, type) => {
			//console.log(collapsed, type);
			setCollapsed(collapsed);
		}}
		>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={[sel_key]}
				defaultOpenKeys={[sel_key]}
				items={side_menu}
				openKeys={stateOpenKeys}
				onOpenChange={onOpenChange}
				//onTitleClick={onMenuClick}
				onClick={onMenuClick}
			/>
		</Sider>
	);

};

export default SideMenu;