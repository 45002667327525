import React from "react";
import { useTranslation } from "react-i18next";

const AnalisPadr = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return(
		<div>
			<h2 className="title is-1">{t("analise_padroes")}</h2>
			<br/>
			<hr/>
			<br/>
		</div>
	);
}

export default AnalisPadr;