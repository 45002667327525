import axios from "axios";

//const baseURL = "https://localhost:7222/api";
const baseURL = "https://tlsengenharia.com.br:32592/api";

/*export const api = axios.create({
  baseURL: baseURL,
  headers: {
		'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
  }  
});*/

export default axios.create({
  baseURL: baseURL
});

export const axiosPrivate = axios.create({
  baseURL: baseURL,
  //withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
		'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' ,
    //'Accept': "application/json",
    'Access-Control-Allow-Credentials':true
  }
});

/*api.interceptors.request.use(async config => {
    // Declaramos um token manualmente para teste.
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9";
  

    if (token) {
      api.defaults.headers.authorization = 'Bearer ${token}';

      
    }
  
    return config;
  });*/

//export default api;