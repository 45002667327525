import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { AutoComplete, Button,  Cascader, Checkbox, Col, Form, Input, InputNumber, Row, Select, Alert, Modal } from 'antd';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';
import Terms from '../../components/Terms';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Cadastro = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { hs } = useAuth();
	
	const axiosPrivate = useAxiosPrivate();
	let navigate = useNavigate();

	const userRef = useRef();
	const errRef = useRef();
	const sucRef = useRef();
 
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');	
	const [pwd, setPwd] = useState('');
	const [matchPwd, setMatchPwd] = useState('');
	const [terms, setTerms] = useState(false);

	const [validName, setValidName] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const [validPwd, setValidPwd] = useState(false);
	const [validMatch, setValidMatch] = useState(false);
	const [validTerms, setValidTerms] = useState(false);
	
	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	const [visible, setVisible] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
   
	const handleOpenModal = () => {
	  setVisible(true);
	};
   
	const handleAcceptTerms = () => {
	  setAcceptedTerms(true);
	  setVisible(false);
	};
   
	const handleCancel = () => {
	  setVisible(false);
	};






	const [form] = Form.useForm();
	// const onFinish = async (values) => {
	// 	//this.preventDefault();
	// 	//form.preventDefault();
	//  	console.log('Received values of form: ', values);
	// 	setErrMsg('No Server Response');
	// 	//errRef.current.focus();
	// };

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);
	
		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);
		
		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	useEffect(() => {
		setErrMsg('');
		setValidName(name !== "");
		setValidEmail(email !== "");		
		setValidPwd(PWD_REGEX.test(pwd));
		setValidMatch(pwd === matchPwd);
		setValidTerms(terms);
		setSuccess(false);
	}, [name, email, pwd, matchPwd, terms])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = name !== "";
		const v2 = email !== "";
		const v3 = PWD_REGEX.test(pwd);
		const v4 = pwd === matchPwd;
		const v5 = terms;
		const hashedPassword = bcrypt.hashSync(pwd, hs);

		if (!v1 || !v2 || !v3 || !v4 || !v5) {
			setErrMsg(t("cadastro_msg.preenchimento_invalido"));
			return;
		}

		let api_ret = false;

		try {
			const response = await axiosPrivate.post('/UserRegistration', {
				un : name,
				em : email, 
				sn : hashedPassword
			});

			if (response.status === 200) {

				if(response.data.length > 0){
					let return_info = JSON.parse(response.data[0].jsonInfo);
					switch(return_info[0].error_code) {
						case 0:
							setSuccess(true);
							form.resetFields();
							navigate("/verificar_email/?email="+email, {replace: true});
							break;
						case 1:
							setErrMsg("cadastro_msg.falha_envio");
							setSuccess(false);
							break;
						case 2:
							setErrMsg("cadastro_msg.email_cadastrado");
							setSuccess(false);
							break;
						case 3:
							setErrMsg("cadastro_msg.error_3");
							setSuccess(false);
							break;
						default:
							setErrMsg("cadastro_msg.error_4");
							setSuccess(false);
					 }

				}else{
					setErrMsg("cadastro_msg.error_5");
					setSuccess(false);
				}

			} else {
				setErrMsg("cadastro_msg.error_6");
				setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg("cadastro_msg.falha_comunicacao");
				setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg("cadastro_msg.error_8");
				setSuccess(false);
			} else {
				setErrMsg("cadastro_msg.error_9");
				setSuccess(false);
			}
		}
	}

	return(  
		<div>
			<h2 className="title is-1">{t("cadastro")}</h2>
			<br/>
			<hr/>
			<br/>
			<div 
				style={{
				//height: 300, 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center'
				}}
			>
				<Form
					{...formItemLayout}
					form={form}
					//onSubmit={handleSubmit}
					name="register"
					//onFinish={handleSubmit}
					style={{
						maxWidth: 600,
					}}
					scrollToFirstError
				>
					{errMsg != "" && success === false ? (
						<><Alert ref={errRef} message={t(errMsg)} type="error" showIcon /><br/></>
					) : (<></>)}
					
					{success === true && errMsg == "" ? (
						<><Alert ref={sucRef} message={t("cadastro_msg.cadastro_sucess")} type="success" showIcon/><br/></>
					) : (<></>)}

					<Form.Item
						id="name_inp"
						name="nome"
						label={t("cadastro_msg.nome_completo")}
						onChange={(e) => setName(e.target.value)}
						maxLength={200}
						rules={[
							{
								required: true,
								message: t("cadastro_msg.nome_completo_err"),
								whitespace: true
							},
							() => ({
								validator(_, value) {
								if (value !== "") {
									return Promise.resolve();
								}
									return Promise.reject()//new Error('A confirmação está diferente da senha!'));
								},
							}),
						]}
      				>
        					<Input />
      				</Form.Item>

					<Form.Item
						id="email_inp"
						name="email"
						label={t("cadastro_msg.email")}
						onChange={(e) => setEmail(e.target.value)}
						maxLength={255}
						rules={[
							{
								required: true,
								type: 'email',
								message: t("cadastro_msg.email_err"),
							},
							() => ({
								validator(_, value) {
								if (value !== "") {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.falta_email")));
								},
							}),
						]}
					>
        					<Input />
      				</Form.Item>

					<Form.Item
						id="password_inp"
						name="password"
						label={t("cadastro_msg.senha")}
						onChange={(e) => setPwd(e.target.value)}
						tooltip={t("cadastro_msg.senha_tooltip")}
						rules={[
							{
								required: true,
								message: t("cadastro_msg.falta_senha"),
							},
							() => ({
								validator(_, value) {
								if (PWD_REGEX.test(value)) {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.senha_err")));
								},
							}),
						]}
						hasFeedback
					>
        					<Input.Password />
      				</Form.Item>

					<Form.Item
						id="confirm_inp"
						name="confirm"
						label={t("cadastro_msg.confir_senha")}
						onChange={(e) => setMatchPwd(e.target.value)}
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: t("cadastro_msg.falta_confir_senha"),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.confir_senha_err")));
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						id="agree_inp"
						name="agreement"
						valuePropName="checked"
						onChange={(e) => setTerms(e.target?.checked ? true : false)}
						rules={[
							{
							validator: (_, value) =>
								value ? Promise.resolve() : Promise.reject(new Error(t("cadastro_msg.termos_err"))),
							},
						]}
						{...tailFormItemLayout}
					>
						<Checkbox checked={acceptedTerms} readOnly={true}
							//onChange={(e) => setTerms(e.target?.checked ? true : false)}
						>
          					{t("cadastro_msg.li_termos")} <a onClick={handleOpenModal}>{t("cadastro_msg.termos")}</a>
        					</Checkbox>
      				</Form.Item>

					<Form.Item {...tailFormItemLayout}>
						<SubmitButton disabled={!validName || !validEmail || !validPwd || !validMatch || !validTerms  ? true : false} form={form} on_click={handleSubmit}>{t("cadastro_msg.cadastrar")}</SubmitButton>
					</Form.Item>
					<Modal
				    		width={'95%'}
						height={'95%'}
						style={{top:10, maxWidth:900}}
						//title="Terms and Conditions"
						open={visible}
						onCancel={handleCancel}
						footer={[
							<Button key="accept" type="primary" onClick={handleAcceptTerms}>
							{t('cadastro_msg.aceito')}
							</Button>,
						]}
						>
						<h2>{t('termos_condicoes')}</h2>
						<hr/>
						<Terms/>
					</Modal>
    				</Form>
				    
    			</div>
          </div>  
	)
};

export default Cadastro