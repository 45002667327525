import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Button, Modal, Flex, message, Alert } from 'antd';
const ExcelJS = require("exceljs");
const { forwardRef, useRef, useImperativeHandle } = React;

const ModalSelNum = forwardRef((props, ref ) => {

	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const [modal_info, setModalInfo] = useState({
		visible: false,
		selected_numb : [],
		combinations : [],
		qty_disp : 0,
		qty_tot : 0,
		alert_visible : false,
		alert_message : ''
	});

	const handleClose = () => {
		modal_info.alert_visible = false;
		updateModal();
	};

	const updateModal = () => {
		setModalInfo(()=>{
			return {
				visible: true,
				selected_numb : modal_info.selected_numb,
				combinations : modal_info.combinations,
				qty_disp : modal_info.qty_disp,
				qty_tot : modal_info.qty_tot,
				alert_visible : modal_info.alert_visible,
				alert_message : modal_info.alert_message
			};
		});
	};
	const handleOk = (e) => {
		setModalInfo(()=>{
			return {
				visible: false,
				selected_numb : [],
				combinations : 0,
				qty_disp : 0,
				qty_tot : 0,
				alert_visible : false,
				alert_message : ''
			};
		});
	};
	const handleCancel = (e) => {
		setModalInfo(()=>{
			return {
				visible: false,
				selected_numb : [],
				combinations : 0,
				qty_disp : 0,
				qty_tot : 0,
				alert_visible : false,
				alert_message : ''
			};
		});
	};
	const selNum = (num)=>{

		modal_info.alert_visible = false;

		let index = modal_info.selected_numb.indexOf(num);
		if(index !== -1){
			modal_info.selected_numb.splice(index, 1);
		}else{
			if(modal_info.selected_numb.length < modal_info.qty_tot){
				modal_info.selected_numb.push(num);
			}else{
				modal_info.alert_message = t('desdobramentos_lot.ja_for_sel_01')+modal_info.qty_tot+t('desdobramentos_lot.ja_for_sel_02');
				modal_info.alert_visible = true;
			}
		}
		updateModal();
	};

	useImperativeHandle(ref, () => ({

		showModalInfo(qty_num_tot, qty_disp, combin) {

			let combin_json = (combin === undefined || combin === '') ? [] : JSON.parse(combin);
			setModalInfo(()=>{
				return {
					visible: true,
					selected_numb : [],
					combinations : combin_json,
					qty_disp : qty_disp,
					qty_tot : qty_num_tot,
					alert_visible : false,
					alert_message : ''
				};
			});
		}

	}));

	function comparaNumeros(a,b) { if (a == b) return 0; if (a < b) return -1; if (a > b) return 1; }

	const exportExcelFile = () => {
		const workbook = new ExcelJS.Workbook();
		workbook.creator = 'TLS Engenharia';
		const sheet = workbook.addWorksheet("JOGOS");

		modal_info.selected_numb.sort(comparaNumeros);

		modal_info.combinations.map((comb, index) => {

			//Converte para os números selecionados
			let new_comb = [];
			comb.map((num, index) => {
				new_comb[index] = modal_info.selected_numb[num-1];
			});

			sheet.getRow(index+1).values = new_comb;
		});

		workbook.xlsx.writeBuffer().then(function (data) {
			const blob = new Blob([data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.href = url;
			anchor.download = "jogos_loteria.xlsx";
			anchor.click();
			window.URL.revokeObjectURL(url);
		});
	};

	return (
	<>
		<Modal
			title={t('desdobramentos_lot.selecao_numeros')+" ( "+modal_info.selected_numb.length+" / "+modal_info.qty_tot+" )"}
			open={modal_info.visible}
			onOk={handleOk}
			onCancel={handleCancel}
			okButtonProps={{
				disabled: false,
			}}
			cancelButtonProps={{
				disabled: false,
			}}
			footer=""
			width={'100%'}
			style={{maxWidth:520, top:10}}
		>
			<hr/>
			<br/>
			<Flex wrap gap="small">
				{Array.from(
					{
					length: modal_info.qty_disp,
					},
					(_, i) => (
					<Button key={i} type={(modal_info.selected_numb.indexOf(i + 1) >= 0 ? 'primary' : 'default')} shape="circle" size="large" onClick={()=>{selNum(i + 1)}} numb={i}>
						{((i < 9) ? '0' : "") +(i + 1)}               
					</Button>
					),
				)}
			</Flex>
			<br/>
			{modal_info.alert_visible && (
				<>
					<Alert
					description= {modal_info.alert_message}
					type="info"
					showIcon
					closable 
					afterClose={handleClose}
					/>
					<br/>
				</>
			)}
				
			<Flex wrap gap="small" justify={'center'} align={'center'}>               
				<Button type={(modal_info.selected_numb.length === modal_info.qty_tot) ? 'primary' : 'default' } icon={<DownloadOutlined />} size={18} onClick={()=>{
					if(modal_info.selected_numb.length < modal_info.qty_tot){
						modal_info.alert_message = t('desdobramentos_lot.sel_para_gerar_01')+modal_info.qty_tot+t('desdobramentos_lot.sel_para_gerar_02');
						modal_info.alert_visible = true;
						updateModal();
					}else{
						exportExcelFile();
					}}
					}>
				Download
				</Button>
			</Flex>
		</Modal>
	</>
	);
});
export default ModalSelNum;