import React from "react";
import { Flex, Result } from 'antd';
import Card from "../components/Card";
import { useTranslation } from "react-i18next";

const SubCategoria = ({ title, items }) => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	let sub_itens = { children: [] };
	items.map((item, index) => {
		if (title === item.name && item.children !== null) {
			sub_itens = item;
		}
	});

	return (
		<div>
			<h2 className="title is-1">{t(title)}</h2>
			<br />
			<hr />
			<br />
			<Flex wrap gap="small">
				{sub_itens.children.map((card, index) => (
					<Card key={index + 1} title={t(card.name)} image={card.img} text={''} route={card.route} />
				))}
			</Flex>
		</div>
	)

};

export default SubCategoria