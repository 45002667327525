import React from 'react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Space, Select } from 'antd';
// import '../styles/card.css';
import { useTranslation } from "react-i18next";
import { Translate } from "../services/translate";

const SubscribeCard = ({card_info }) => {

	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const [functionalities, setFunctionalities] = useState([]);
	const [prices, setPrices] = useState([]);
	const [price, setPrice] = useState("");
	const [subscription_id, setSubscriptionId] = useState("");
	const qtd_funct = 5;

	const handleChange = (value) => {
		setSubscriptionId(value);
		card_info.subscription_prices.map((item, index) =>
		{
			if (item.code_id === value) {
				setPrice("R$ "+item.price.toFixed(2)+" / ")
			}
		})
	}

	let functionalities_arr = [];
	let prices_arr = [];

	const load_info = () => {
		
		functionalities_arr = [];
		prices_arr = [];

		let loop = (card_info.max_func < qtd_funct) ? qtd_funct : card_info.max_func;
		for (var i = 0; i < loop; i++) {
			//console.log(card_info.subscription_functionalities[i], i, card_info.subscription_functionalities.length);

			functionalities_arr.push(
				i < card_info.subscription_functionalities.length ? "funcionalidades_msg."+card_info.subscription_functionalities[i].functionality : ""
			);
		}

		for (var i = 0; i < card_info.subscription_prices.length; i++) {
			prices_arr.push(
				{
					"label": <Translate path={"assinaturas_msg."+card_info.subscription_prices[i].period}/>,
					"value": card_info.subscription_prices[i].code_id
				}
			);
		}
	}

	useEffect(() => {
		load_info();
		setFunctionalities(()=>{
			return [...functionalities_arr];
		});
		setPrices(() => {
			return[...prices_arr]
		});
		handleChange(prices_arr[0].value);
	}, []);

	return (
		<div key="{card_info.subscription}" className="columns">
			<ul className="price">
				<li className="header">{t("assinaturas_msg."+card_info.subscription)}</li>
				<li className="grey">
					<>{price}</>
					<Select
						defaultValue={card_info.subscription_prices[0].code_id}
						style={{
							width: 100,
						}}
						size='large'
						onChange={handleChange}
						options={prices}						
					/>
					
				</li>
				{
					functionalities.map((item, index) =>
						(<li key={index}><Translate path={item != "" ? item : "-"}/></li>)
					)}
				{subscription_id != "" ? <li className="grey"><Link to={ window.location.origin.toString() + '/assinar_plano/?sb='+subscription_id} className="button" >{t("assinaturas_msg.assinar")}</Link></li> : ""}
			</ul>
		</div>
	);

};

export default SubscribeCard;