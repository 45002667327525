import React, { useState, useEffect } from "react";
import { Select, Space, Table, Tooltip, Flex , Spin, notification, Button, Popconfirm } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import ModalSelNum from "../components/ModalSelNum";
import ModalProbab from "../components/ModalProbab";
//import { list_desdobramentos } from "../services/index";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../hooks/useAuth';

const { forwardRef, useRef, useImperativeHandle } = React;   


let modalidades = [
	{
		key:0,
		value: 'dupla_sena',
		label: 'Dupla Sena',
		qty_comb_min: 6,
		qty_comb_max: 15,
		qty_result: 6,
		qty_disp: 50,
		qty_min_gain : 3,
		combinacoes: [],
		probabilities: [],
		public_only: true
	},
	{
		key:1,
		value: 'lotofacil',
		label: 'Lotofácil',
		qty_comb_min: 15,
		qty_comb_max: 20,
		qty_result: 15,
		qty_disp: 25,
		qty_min_gain : 11,
		combinacoes: [],
		probabilities: [],
		public_only: true
	},
	{
		key:2,
		value: 'mega_sena',
		label: 'Mega Sena',
		qty_comb_min: 6,
		qty_comb_max: 20,
		qty_result: 6,
		qty_disp: 60,
		qty_min_gain : 4,
		combinacoes: [],
		probabilities: [],
		public_only: true
	},
	{
		key:3,
		value: 'quina',
		label: 'Quina',
		qty_comb_min: 5,
		qty_comb_max: 15,
		qty_result: 5,
		qty_disp: 80,
		qty_min_gain : 2,
		combinacoes: [],
		probabilities: [],
		public_only: true,
		disabled: false
	}
	];

	const onChange = (pagination, filters, sorter, extra) => {
		//console.log('params', pagination, filters, sorter, extra);
	};
	//let api_return = true;

const DesdobramentosLoteria = () => {

	window.scrollTo(0, 0);

	const { auth, habRoles } = useAuth();
	const hab_func = habRoles(['7FD98EF2-F1CD-4643-A954-A536E84DFA3F']);
	//console.log(hab_func);
	const navigate = useNavigate();

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const axiosPrivate = useAxiosPrivate();

	const handleChange = (value) => {
		setModalidadeSel(value);
		modalidades.map((modalidade, index) => {
			if(value === modalidade.value){

				//Atualizar a tabela com as combinações já carregadas anteriormente
				if(modalidade.combinacoes.length === 0 || (hab_func === true && modalidade.public_only === true)){
					//Solicitar informações para a API
					setLoadDesdob(true);									
					getModalidade(index,modalidade.qty_comb_min, modalidade.qty_comb_max, modalidade.qty_result, modalidade.qty_disp);
				}else{
					setDatasource(()=>{
						return [...modalidade.combinacoes];
					});
					//api_return = true;
				}
			}
		});
	
	};

	const getModalidade = async (index,qty_comb_min, qty_comb_max, qty_result, qty_disp) => {

		try {
			const response = await axiosPrivate.post('/LotteryCombinations', {
				qty_comb_min : qty_comb_min,
				qty_comb_max : qty_comb_max, 
				qty_result : qty_result, 
				qty_disponible : qty_disp,
				public_only: hab_func === true ? 0 : 1
			});

			if (response.status === 200) {
				if(response.data.length > 0){
					modalidades[index].combinacoes = JSON.parse(response.data[0].jsonInfo);
						modalidades[index].combinacoes.map((comb, i) => {
							comb.qty_disp = modalidades[index].qty_disp;
							comb.qty_min_gain = modalidades[index].qty_min_gain;
							comb.qty_result = modalidades[index].qty_result;							
						});
					modalidades[index].public_only = (hab_func) ? false : true;

					setDatasource(()=>{
						return [...modalidades[index].combinacoes];
					});
				}else{
					setDatasource(()=>{
						return [];
					});
				}
				//api_return = true;

			} else {
				setDatasource(()=>{
					return [];
				});
			}
		} catch (err) {
			setDatasource(()=>{
				return [];
			});
		}
		setLoadDesdob(false);
	};
	
	const confirm = () => {
		navigate(window.location.origin.toString() + '/assinaturas');
	}

	const [dataSource, setDatasource] = useState([]);
	const [modalidade_sel, setModalidadeSel] = useState("");
	const [loading_desdobramento, setLoadDesdob] = useState(false);

	const columns = [
		{
			title: '',
			width: '5vw',
			align: 'center',
			/*render: (_, record) => (
				<a><Tooltip title={"Escolha "+record.qty_num_tot+" números entre os "+record.qty_disp+" disponíveis e se acertar no mínimo "+record.qty_hits+" deles, terá ao menos um jogo com "+record.qty_min_res+" acertos, realizando "+record.qty_comb+" jogos com "+record.qty_num_comb+" números cada."} trigger="click"><InfoCircleOutlined /></Tooltip></a>
			   )*/
			render: (_, record) => {
				return (<a onClick={() => {showModalProb(record.qty_result, record.qty_num_tot, record.qty_disp, record.qty_min_gain, record.prob_info);}}><InfoCircleOutlined/></a>)
			}
		},
		{
		  title: t('desdobramentos_lot.num_por_jogo'),
		  dataIndex: 'qty_num_comb',
		//   filters: [],
		//   filterMode: 'tree',
		//   width: '30%',
			width: '14vw',
			align: 'center'
			//ellipsis: true,
		},
		{
			title: t('desdobramentos_lot.res_esperado'),
			dataIndex: 'qty_min_res',
			width: '14vw',
			align: 'center'
		},
		{
			title: t('desdobramentos_lot.num_envolv'),
			dataIndex: 'qty_num_tot',
			width: '14vw',
			align: 'center'
		},
		{
			title: t('desdobramentos_lot.cond_acertos'),
			dataIndex: 'qty_hits',
			width: '14vw',
			align: 'center'
		},		
		{
			title: t('desdobramentos_lot.qtd_jogos'),
			dataIndex: 'qty_comb',
			width: '14vw',
			align: 'center'
		},
		{
			title: t('desdobramentos_lot.acuracidade'),
			//dataIndex: 'accuracy',
			width: '14vw',
			align: 'center',
			render: (_, record) => {
				return ((record.accuracy).toFixed(2)+"%")
			}
		},		
		{
			title: '',
			fixed: 'right',
			width: '5vw',
			align: 'center',
			   render: (_, record) => {

				if(record.public || hab_func){
					return (<a onClick={() => {showModal(record.qty_num_tot, record.qty_disp,record.comb_info);}}><DownloadOutlined/></a>)
				}else{
					//return (<a onClick={() => {alert(t('desdobramentos_lot.req_plano'));}}><StopOutlined/></a>)
					//return (<a onClick={openNotification}><StopOutlined/></a>)
					return(
						<Popconfirm
							icon={<InfoCircleOutlined/>}
							placement="left"
							title={t('requer_assinatura')}
							description={t('requer_assinatura_texto')}
							//onConfirm={confirm}
							okText={<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/assinaturas'}>{t('assinaturas')}</Link>}
							cancelText={t('fechar')}
							>
							<a><StopOutlined/></a>
						</Popconfirm>
					)
				}
			// 	<StopOutlined />
			//    }(
			// 	<a onClick={() => {alert(record.public);/*showModal(record.qty_num_tot, record.qty_disp,record.comb_info);*/}}><DownloadOutlined/></a>
			//    ),
			   }
		}	
	   ];

	const modalRef = useRef();
	const showModal = (qty_num_tot, qty_disp, combin) => {
		modalRef.current.showModalInfo(qty_num_tot, qty_disp, combin)
	};

	const modalProbRef = useRef();
	const showModalProb = (qty_result,qty_num_tot, qty_disp, qty_min_gain, prob) => {
		modalProbRef.current.showModalInfo(qty_result,qty_num_tot, qty_disp, qty_min_gain, prob)
	};

	return(  
		<Spin spinning={loading_desdobramento} indicator={<LoadingOutlined style={{ fontSize: 96}} spin />}>
			<div>
				<h2 className="title is-1">{t("desdobramentos")}</h2>
				<br/>
				<hr/>
				<br/>
				<Flex wrap gap="small" justify={'center'} align={'center'}> 
					<label>
						{t('desdobramentos_lot.modalidade')}: <Select
							defaultValue=""
							style={{
							width: 120,
							}}
							onChange={handleChange}
							options={modalidades}
						/>
					</label>
				</Flex>
				<br/>
				<br/>
				{modalidade_sel !== "" ?
					<Table id="tableList"
					
					size="small"
					columns={columns} 
					dataSource={dataSource} 
					onChange={onChange}
					pagination={{ pageSize: 50, size:"small", hideOnSinglePage:true/*,showSizeChanger: true */}}
					scroll={{
						x: 700,
					}}
				/>
				: null}
				<ModalSelNum ref={modalRef}></ModalSelNum>
				<ModalProbab ref={modalProbRef}></ModalProbab>
			</div>  
		</Spin>
	)  
};

export default DesdobramentosLoteria