import React, {useState} from 'react';
import { useCookies } from 'react-cookie';
import { Button, Modal} from 'antd';
import '../styles/terms.css';
import { useTranslation } from "react-i18next";
import Terms from '../components/Terms';

export default function CookieConsent(){

     const [cookies,setCookie] = useCookies(["cookieConsent"]);
     const [modalOpen, setModalOpen] = useState(cookies);

     const { t, i18n: {changeLanguage, language} } = useTranslation();

     const [visible, setVisible] = useState(false);
   
	const handleOpenModal = () => {
	  setVisible(true);
	};
   
	const handleCloseTerms = () => {
	  setVisible(false);
	};
   
	const handleCancel = () => {
	  setVisible(false);
	};

     const giveCookieConsent = () => {
          setCookie("cookieConsent",true,{path: "/"});
          setModalOpen(false);
     };

     return (
          <>
               <Modal
                    title={t('cookies_msg.title')}
                    style={{bottom:10, maxWidth:900}}
                    open={modalOpen}
                    onOk={giveCookieConsent}
                    onCancel={() => setModalOpen(false)}
                    width={'100%'}
                    footer={[
                         <Button key="back" onClick={() => setModalOpen(false)}>
                           {t('fechar')}
                         </Button>,
                         <Button key="submit" type="primary" onClick={giveCookieConsent}>
                           {t('cadastro_msg.aceito')}
                         </Button>,
                       ]}
                    >
                    <div >
                         <hr/>
                         <div className='term_just'>
                              {t('cookies_msg.desc_cookies_01')}
                              <a onClick={handleOpenModal}>{t('termos_condicoes')}</a>
                              {t('cookies_msg.desc_cookies_02')}
                         </div>
                    </div>
               </Modal>
               <Modal
                    width={'95%'}
                    height={'95%'}
                    style={{top:10, maxWidth:900}}
                    open={visible}
                    onCancel={handleCancel}
                    footer={[
                         <Button key="close" onClick={handleCloseTerms}>
                         {t('fechar')}
                         </Button>,
                    ]}
                    >
                    <h2>{t('termos_condicoes')}</h2>
                    <hr/>
                    <Terms/>
               </Modal>
          </>
     );
}