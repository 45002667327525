import React from "react";
import { useEffect, useState } from "react";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import { Link } from "react-router-dom";
import Translator from "../../../components/Translator";
import { Divider, List, Typography, Card } from 'antd';
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AdminDash = () => {

	window.scrollTo(0, 0);
	const axiosPrivate = useAxiosPrivate();
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	
	const [qtd_users, setQtdUsers] = useState(0);

	useEffect(() => {
		getInfoDash();
	}, []);

	const getInfoDash = async () => {

		try {
			const response = await axiosPrivate.post('/AdminDashboard');
			//console.log(response);
			if (response.status === 200) {

				if(response.data.length > 0){
					let dash_info = JSON.parse(response.data[0].jsonInfo);
					setQtdUsers(dash_info[0].qtd_usuarios)
					//console.log(dash_info[0]);
				}else{
					// setDatasource(()=>{
					// 	return [];
					// });
				}
			} else {
				// setDatasource(()=>{
				// 	return [];
				// });
			}
		} catch (err) {
			// setDatasource(()=>{
			// 	return [];
			// });
		}
	};

	return(
		<div>
			<h2 className="title is-1">{t('administracao.dashboard')}</h2>
			<br/>
			<hr/>
			<br/>
			<Card
				title="Usuários"
				bordered={false}
				style={{
					width: 300,
				}}
				>
				<h1>{qtd_users}</h1>
			</Card>
		</div>
	);
}


export default AdminDash;